import { useState, useEffect, useLayoutEffect, useRef } from "react"
import './CardRecognitionColumn.css'
import getCardImageUrl from "../Game/CardDatabase"
import Button from "../Commons/Buttons"
import { useLanguageContext } from "../Commons/Translations/languageContext"

export default function CardRecognitionColumn(props) {
    const { t } = useLanguageContext()
    const [focusedCard, setFocusedCard] = useState(false)
    const [history, setHistory] = useState([])
    const historyRef = useRef([])
    const [showZoomedImage, setShowZoomedImage] = useState(false)

    const [cardSearchResults, setCardSearchResults] = useState(false)
    const [cardSearchInProgress, setCardSearchInProgress] = useState(false)

    const historyMaxSize = 20

    const [setFilter, setSetFilter] = useState({
        array: ["BTG", "TBF"],
        KS: false,
        BTG: true,
        TBF: true
    })


    function pushToHistory(card, indexToRemove) {
        let tmp = [...historyRef.current]
        if (indexToRemove >= 0) {
            tmp.splice(indexToRemove, 1)
        }
        if (!(card.image && card.url)) { return tmp }
        let index = tmp.findIndex(c => c.url == card.url)
        if (index >= 0) {
            tmp.splice(index, 1)
        }
        tmp.unshift({ ...card })
        return tmp
    }
    /*
        const setCardImageUrlFromAPI = async (ref) => {
            let cardUrlTmp = await getCardImageUrl(ref)
            if (cardUrlTmp != undefined) {
                if (focusedCard) {
                    setHistory(pushToHistory(focusedCard), -1)
                }
                setFocusedCard({ image: cardUrlTmp, url: "https://www.altered.gg/fr-fr/cards/" + ref, reference: "" + ref })
            } else {
                //setProgressMessage("Invalid QR Code")
            }
        }
    */
    useLayoutEffect(() => {
        historyRef.current = [...history]
        if (history.length > historyMaxSize) {
            let tmp = [...history]
            tmp.length = historyMaxSize
            historyRef.current = tmp
            setHistory(tmp)
        }
    }, [history])

    useEffect(() => {
        let code = props.latestScannedQRCode
        if (code) {
            addQRCode(code)
        }
    }, [props.latestScannedQRCode])

    async function addQRCode(c) {
        // ENLEVER LES DOUBLONS DANS CODES
        let n = 0
        let newCards = []
        let alreadyInHistory = historyRef.current.some(e => e.reference === c)
        if (!alreadyInHistory && !(focusedCard && focusedCard.reference === c)) {
            n += 1
            let cardUrlTmp = await getCardImageUrl(c)
            newCards.push({ image: cardUrlTmp, url: "https://www.altered.gg/fr-fr/cards/" + c, reference: c })
            if (newCards.length == n) {
                addCardsToHistoryAndFocus(newCards)
            } else {
            }
        } else if (alreadyInHistory) {
            let i = 0
            historyRef.current.forEach((hc) => {
                if (hc.reference == c) {
                    let tmp = [...historyRef.current]
                    tmp.splice(i, 1)
                    if (focusedCard) {
                        tmp.unshift({ ...focusedCard })
                    }
                    setHistory(tmp)
                    setFocusedCard({ ...hc })
                    return
                }
                i += 1
            })
        }
    }

    function addCardsToHistoryAndFocus(cards) {
        let tmp = [...historyRef.current]
        if (focusedCard) {
            tmp.unshift({ ...focusedCard })
        }
        let c = cards[cards.length - 1]
        cards.length = cards.length - 1
        tmp.unshift(...cards)
        setHistory(tmp)

        setFocusedCard({ ...c })
    }

    const searchForCardsByName = async () => {
        const cardName = document.getElementById('cardNameSearchField').value
        if (cardName == "") { return }
        setCardSearchInProgress(true)
        let url = "https://api.altered.gg/cards?itemsPerPage=20&translations.name=" + cardName
        try {
            const reponse = await fetch(url);
            const jsonResponse = await reponse.json();
            console.log(jsonResponse)
            const cards = []
            jsonResponse["hydra:member"].forEach((c) => {
                if (cards.length < 20) {
                    cards.push({ image: c["imagePath"], url: "https://www.altered.gg/fr-fr/cards/" + c["reference"], reference: c["reference"], set: getSetFromReference(c["reference"]) })
                }
            })

            setCardSearchResults(cards)
            setCardSearchInProgress(false)
        } catch (err) {
            setCardSearchInProgress(false)
            console.error(err)
        }
    }

    const focusCard = (card) => {
        if (focusedCard) {
            setHistory(pushToHistory(focusedCard), -1)
        }
        setFocusedCard({ ...card })
    }

    function searchForCardSubmit(e) {
        if (e.key === 'Enter') {
            searchForCardsByName()
        }
    }

    const changeFilter = (setId) => {
        let tmp = { ...setFilter }
        tmp[setId] = !tmp[setId]
        tmp.array = []
        if (tmp.KS) {
            tmp.array.push("KS")
        }
        if (tmp.BTG) {
            tmp.array.push("BTG")
        }
        if (tmp.TBF) {
            tmp.array.push("TBF")
        }
        setSetFilter(tmp)
    }

    const getSetFromReference = (ref) => {
        if (ref.includes("COREKS")) {
            return "KS"
        } else if (ref.includes("ALIZE")) {
            return "TBF"
        } else {
            return "BTG"
        }
    }

    return (
        <div className="result d-flex flex-column">
            <div class="card-search">
                <div class={("d-flex flex-row") + (cardSearchInProgress ? " opacity-0" : "")}>
                    <input type="text" id="cardNameSearchField" placeholder={t("game.card_search.placeholder")} class="form-control" aria-describedby="basic-addon3" onKeyDown={searchForCardSubmit} ></input>
                    <Button onClick={() => searchForCardsByName()}><img src={require('../Game/Icons/loupe.png')} /></Button>
                </div>
                {cardSearchInProgress && (<p class="m-0 position-absolute">{t("game.card_search.in_progress")} </p>)}
            </div>
            <div class="card-recognition-column d-flex flex-column">
                <div class="focused-card-section">
                    {focusedCard ? (
                        <div class="">
                            <img src={focusedCard.image} class="focused-card-image shadowed" onClick={() => setShowZoomedImage(true)} />
                            <a class="wide" href={focusedCard.url} target='_blank'>Details</a>
                            <img class="zoom-button-icon" src={require('../Game/Icons/loupe.png')} />
                        </div>
                    ) : (<div class="">
                        <div class="no-image">
                        </div>
                    </div>)}
                </div>
                <hr class="solid"></hr>
                {/*<p>History</p>*/}
                <div class="history d-flex flex-column">
                    {history.map((card, i) => {
                        return (
                            <div class="history-card" key={i + ":" + card.cardUrl}>
                                <img class="shadowed-light" src={card.image} onClick={() => {
                                    let tmp = [...pushToHistory(focusedCard, i)]

                                    setHistory(tmp)
                                    setFocusedCard({ ...card })
                                }} />
                            </div>
                        )
                    })
                    }
                </div>
                <div class={(showZoomedImage ? "" : "opacity-0 ") + ("fullscreen-panel zoomed-image")} onClick={() => setShowZoomedImage(false)}>
                    <img src={focusedCard.image} class="shadowed" />
                </div>
            </div>
            {cardSearchResults && (
                <div class="card-search-results d-flex flex-column justify-content-start">
                    <div class="d-flex flex-column top-panel">
                        <Button class="mb-2 mt-0" onClick={() => setCardSearchResults(false)}>{t("commons.close")} </Button>
                        <div class="set-selection d-flex flex-row">
                            <Button class="tertiary" selected={setFilter["KS"]} onClick={() => changeFilter("KS")} >KS<img src={require('./Assets/BTGKS.png')} /></Button>
                            <Button class="tertiary" selected={setFilter["BTG"]} onClick={() => changeFilter("BTG")} >BTG<img src={require('./Assets/BTG.png')} /></Button>
                            <Button class="tertiary" selected={setFilter["TBF"]} onClick={() => changeFilter("TBF")} >TBF<img src={require('./Assets/TBF.png')} /></Button>
                        </div>
                    </div>
                    <div class="results-section d-flex flex-column justify-content-start">
                        {cardSearchResults.map((c, i) => {
                            if (setFilter.array.includes(c.set)) {
                                return (<img src={c.image} onClick={() => { focusCard(c); setCardSearchResults(false) }} />)
                            }
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}