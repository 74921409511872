import { useEffect, useRef, useState } from "react"
import PageWithNavigation from "../../Commons/PageWithNavigation"
import "./DeckSetup.css"
import ErrorMessages from "../../Commons/ErrorMessages"
import VirtualBoard from "../VirtualBoard"
import cards from '../../Commons/Data/cards.json'
import { useLanguageContext } from "../../Commons/Translations/languageContext"

export default function DeckSetup(props) {
    const { t, i18n } = useLanguageContext()
    const [errorMessages, setErrorMessages] = useState([])

    const [deckList, setDeckList] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const isDeckLoading = useRef(null)
    const [ajordatDecklist, setAjordatDecklist] = useState(false)

    const closeDeckTester = () => {
        setIsPlaying(false)
        setAjordatDecklist(false)
    }

    useEffect(() => {
        if (props.ajordatDecklist && !isDeckLoading.current) {
            window.history.replaceState(null, "Exalts Table", "/deck-test")

            let cardIdentifiers = props.ajordatDecklist
            if (cardIdentifiers.length == 0) {
                setErrorMessages([...errorMessages, "Empty decklist from link"])
                return
            }
            setAjordatDecklist(cardIdentifiers)
            isDeckLoading.current = true
        }
        /*
        console.log("CARDS JSON EDITING")
        let final = {}
        Object.entries(cards).forEach((k, v) => {
            let tmp = {...k[1]}
            delete tmp.subtypes
            delete tmp.elements.MAIN_EFFECT
            delete tmp.elements.ECHO_EFFECT
            delete tmp.assets
            delete tmp.collectorNumberFormatted
            delete tmp.collectorNumberPrinted
            final[k[0]] = tmp
        })
        console.log(final)
        */
    }, [])


    if (isPlaying) {
        return (<div>
            <div class="w-100 d-flex flex-row portrait-only justify-content-center">
                <h3>This app is not made to be played on a phone</h3>
            </div>
            <div class="landscape-only">
                <VirtualBoard deckList={deckList} closeDeckTester={closeDeckTester} />
            </div>
        </div>)
    }

    if (ajordatDecklist) {
        return (<PageWithNavigation description="Please wait until your decklist is initialyzed" selected="play" component={
            <div>
                <div class="w-100 d-flex flex-row portrait-only justify-content-center">
                    <h3>This app is not made to be played on a phone</h3>
                </div>
                <div class="d-flex flex-row row justify-content-around landscape-only">
                    <ErrorMessages errorMessages={errorMessages} />
                    <div class="d-flex flex-column align-items-center">
                        <h2 class="m-4">Loading...</h2>
                        <p class="decklist-loading">{ajordatDecklist}</p>
                    </div>
                    <div class="d-none">
                        <DecklistSetup completion={(dl) => {
                            setDeckList(dl)
                            setIsPlaying(true)
                        }}
                        forceDecklist={ajordatDecklist.length > 0 ? ajordatDecklist : false} 
                        />
                    </div>
                </div>
            </div>
        } />)
    }

    return (<PageWithNavigation selected="play" component={
        <div class="row justify-content-around">
            <div class="w-100 d-flex flex-row portrait-only justify-content-center">
                <h3>This app is not made to be played on a phone</h3>
            </div>
            <div class="d-flex flex-column col-md-6">
                <DecklistSetup completion={(dl) => {
                    setDeckList(dl)
                    setIsPlaying(true)
                }} />
            </div>
        </div>
    } />)
}

export function DecklistSetup(props) {
    const { t, i18n } = useLanguageContext()
    const [errorMessages, setErrorMessages] = useState([])
    const [isDeckLoadingFeedback, setIsDeckLoadingFeedback] = useState(false)

    useEffect(() => {
        if (props.forceDecklist) {
            setCardsDataFromList(props.forceDecklist)
        }
    }, [props.forceDecklist])

    // Button load from decklist clicked
    const setCardsFromDecklist = () => {
        setIsDeckLoadingFeedback(true)
        let cardIdentifiers = document.getElementById("Decklist").value
        if (cardIdentifiers.length == 0) {
            setErrorMessages([...errorMessages, "Empty decklist"])
            setIsDeckLoadingFeedback(false)
            return
        }
        setCardsDataFromList(cardIdentifiers)
    }

    // Get data using card list
    const setCardsDataFromList = (cardList) => {
        let cardIdentifiers = cardList.split('\n')
        let cardCounts = []

        const batches = []
        while (cardIdentifiers.length) {
            let card = cardIdentifiers.shift()
            if (card.length > 1) {
                card = card.split(' ')
                if (card.length != 2) {
                    setErrorMessages([...errorMessages, "Invalid row in decklist: " + card])
                    setIsDeckLoadingFeedback(false)
                    return
                }
                cardCounts.push(card[0])
                batches.push(card.slice(1, card.length).join(' '))
            }
        }

        let foundCards = []
        let missingCardsReference = []
        for (let n = 0; n < batches.length; n++) {
            let batch = batches[n]
            let card = cards[batch]
            if (card) {
                card.reference = batch
                foundCards.push({ card: card, count: cardCounts[n] })
            } else {
                if (batch.includes('_U_')) {
                    // Guess we do nothing...
                } else {
                    missingCardsReference.push({ ref: batch, count: cardCounts[n] })
                }
            }
        }


        Promise.all(missingCardsReference.map(c => {
            return new Promise((resolve, reject) => {
                let batch = c.ref
                let url = "https://api.altered.gg/cards?reference=" + batch
                if (props.isSecret) {
                    url = "https://api.altered.gg/cards/" + batch
                }
                fetch(url).then(async (response) => {
                    const cardResponse = await response.json();
                    let card = cardResponse['hydra:member']
                    if (card == undefined || card[0] == undefined) {
                        setErrorMessages([...errorMessages, "Invalid card name: " + batch])
                        reject("Card not found")
                    }
                    console.log(card[0])
                    resolve({ card: card[0], count: c.count })
                }).catch((err) => {
                    setErrorMessages([...errorMessages], "Invalid card name " + batch)
                    missingCardsReference.push(batch)
                    reject("Unkown card reference")
                })
            })
        })).then(async result => {
            setIsDeckLoadingFeedback(false)
            let finalResult = []
            let finalCardCounts = []
            for (let n = 0; n < foundCards.length; n++) {
                finalResult.push(foundCards[n].card)
                finalCardCounts.push(foundCards[n].count)
            }
            for (let n = 0; n < result.length; n++) {
                finalResult.push(result[n].card)
                finalCardCounts.push(result[n].count)
            }
            setCardsFromResults(finalResult, finalCardCounts)
        }).catch((err) => {
            console.error(err)
            setIsDeckLoadingFeedback(false)
        })
    }

    // Setup deck with data from DB
    const setCardsFromResults = (result, counts) => {
        let deckList_tmp = []
        for (let i = 0; i < result.length; i++) {
            const card = result[i]
            for (let n = 0; n < counts[i]; n++) {
                let costs = [0, 0]
                let power = [0, 0, 0]

                const getNumbers = (str) => {
                    var numb = str.match(/\d/g);
                    numb = numb.join("");
                    return numb
                }

                if (card["elements"]["MAIN_COST"]) {
                    costs[0] = getNumbers(card["elements"]["MAIN_COST"])
                }
                if (card["elements"]["RECALL_COST"]) {
                    costs[1] = getNumbers(card["elements"]["RECALL_COST"])
                }

                if (card["elements"]["FOREST_POWER"]) {
                    power[0] = getNumbers(card["elements"]["FOREST_POWER"])
                }
                if (card["elements"]["MOUNTAIN_POWER"]) {
                    power[1] = getNumbers(card["elements"]["MOUNTAIN_POWER"])
                }
                if (card["elements"]["OCEAN_POWER"]) {
                    power[2] = getNumbers(card["elements"]["OCEAN_POWER"])
                }

                let lang = i18n.language
                let type = card["type"] ? card["type"] : card["cardType"]["reference"]
                if (type == "LANDMARK_PERMANENT") {
                    type = "PERMANENT"
                }

                let rarity = card["rarity"]
                if (card["rarity"] && card["rarity"]["reference"]) {
                    rarity = card["rarity"]["reference"]
                }

                deckList_tmp.push({
                    name: card["name"][lang] ? card["name"][lang] : card["name"],
                    image: card["imagePath"][lang] ? card["imagePath"][lang] : card["imagePath"],
                    images: card["imagePath"][lang] ? card["imagePath"] : undefined,
                    id: card["id"],
                    type: type,
                    reference: card["reference"],
                    manaCost: costs,
                    power: power,
                    rarity: rarity
                })
            }
        }
        console.log(result)
        console.log(deckList_tmp)
        if (deckList_tmp.length > 20) {
            props.completion(deckList_tmp)
        } else {
            setErrorMessages([...errorMessages, "Decklist too small"])
        }
    }



    return (
        <div class="d-flex flex-row row justify-content-around landscape-only">
            <ErrorMessages errorMessages={errorMessages} />
            <div class="row justify-content-around">
                <div class="d-flex flex-column">
                    <h2 class="m-4">{t("deck_test.title")}</h2>
                    <p>{t("deck_test.content_1") + " "}<a href="https://altered.ajordat.com/fr/decks/" target="_blank" rel="noopener noreferrer">Ajordat's deckbuilder</a>{t("deck_test.content_2") + " "}<a href="https://altered-db.com/" target="_blank" rel="noopener noreferrer">Altered DB</a>.</p>
                    {!isDeckLoadingFeedback && (<button class="mb-2" onClick={() => setCardsFromDecklist()}>{t("deck_test.button")}</button>)}
                    <textarea id="Decklist" placeholder="1 ALT_CORE_B_MU_01_C
3 ALT_CORE_B_MU_04_C
3 ALT_CORE_B_MU_06_C
3 ALT_CORE_B_MU_08_R1
3 ALT_CORE_B_MU_10_C
3 ALT_CORE_B_MU_12_R1
3 ALT_CORE_B_MU_13_C
3 ALT_CORE_B_MU_15_C
3 ALT_CORE_B_MU_18_R1
3 ALT_CORE_B_MU_22_R1
3 ALT_CORE_B_MU_25_C
3 ALT_CORE_B_MU_27_C
3 ALT_CORE_B_MU_28_C
3 ALT_CORE_B_MU_30_R1
"/>
                </div>
            </div>
        </div>
    )
}



export function Locked() {
    const { t, i18n } = useLanguageContext()
    return (<div><p>{t("locked")}</p></div>)
}