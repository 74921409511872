import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './VirtualBoard.css'
import './VirtualBoard_StreamerLayout.css'
import { shuffle, useHorizontalScroll, uuidv4 } from '../QRCodeRecognition/Functions'
import { toHaveFormValues } from '@testing-library/jest-dom/matchers'
import FullScreenPanel from '../Commons/FullScreenPanel'
import { Accordion } from 'react-bootstrap'
import React from 'react'
import LeftBar from '../Game/Components/LeftBar'
import { ImageMemo } from '../Game/Components/Memos'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import DarkMode from '../Commons/DarkMode/DarkMode'
import Button from '../Commons/Buttons'
import Loading from '../Commons/Loading/Loading'
import { useLanguageContext } from '../Commons/Translations/languageContext'
import LanguageSelect from '../Commons/Translations/LanguageSelect'
import { t } from 'i18next'
import GlassBackground from '../Commons/Misc/GlassBackground'
import VirtualShortcutsMemo from '../Commons/Misc/VirtualShortcutsMemo'

export default function VirtualBoard(props) {
    const { t } = useLanguageContext()
    if (!Array.prototype.toSpliced) {
        Array.prototype.toSpliced = function (...args) {
            let tmp = this
            tmp.splice(...args)
            return tmp;
        };
    }

    const [deckList, setDeckList] = useState(props.deckList)

    // Board data
    const [deck, setDeck] = useState([])
    const [hero, setHero] = useState(false)
    const [heroExpedition, setHeroExpedition] = useState([])
    const [companionExpedition, setCompanionExpedition] = useState([])
    const [reserve, setReserve] = useState([])
    const [landmarks, setLandmarks] = useState([])
    const [mana, setMana] = useState([])
    const [discard, setDiscard] = useState([])
    const [hand, setHand] = useState([])

    const [timeTravel, setTimeTravel] = useState([])
    const [currentTurn, setCurrentTurn] = useState(0)
    const [hasUsedTimeTravel, setHasUsedTimeTravel] = useState(false)
    const [hideTimeTravelWarning, setHideTimeTravelWarning] = useState(false)

    const cardsPositions = useRef({})
    const [virtualBoardId, setVirtualBoardId] = useState(uuidv4())

    const saveTimeTravelData = (turn, data) => {
        let t = [...timeTravel]

        if (turn >= timeTravel.length) {
            t.push(data)
        } else {
            t = t.slice(0, turn)
            t.push(data)
        }

        setTimeTravel(t)
        setCurrentTurn(t.length - 1)
    }

    const travelThroughTime = (turn) => {
        if (turn < 0 || turn >= timeTravel.length) { return }

        setCurrentTurn(turn)

        let data = timeTravel[turn]
        setDeck(data.deck)
        setHero(data.hero)
        setHeroExpedition(data.heroExpedition)
        setCompanionExpedition(data.companionExpedition)
        setReserve(data.reserve)
        setLandmarks(data.landmarks)
        setMana(data.mana ? data.mana : [])
        setDiscard(data.discard)
        setHand(data.hand)

        let item = window.localStorage.getItem("board-data")
        if (item) {
            let data = JSON.parse(item)
            data.hasUsedTimeTravel = true
            window.localStorage.setItem("board-data", JSON.stringify(data));
        }
        setLastBoardDataChangeSource("deck-tester")
    }


    // Board state
    const [cardFocused, setCardFocused] = useState(false)
    const [cardDragged, setCardDragged] = useState(false)
    const [selectableZone, setSelectableZone] = useState({
        any: false,
        heroExpedition: false,
        companionExpedition: false,
        mana: false,
        reserve: false,
        landmarks: false,
        discard: false
    })
    const [showDiscard, setShowDiscard] = useState(false)
    const [showManaPanel, setShowManaPanel] = useState(false)
    const quickTokenModeRef = useRef(false)
    const [tokens, setTokens] = useState([
        {
            "name": "Scarabot",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/ALIZE/CARDS/ALT_ALIZE_B_AX_31/JPG/fr_FR/8536313869ea556f2830c14682266657.png",
            "id": "01HKAFJNMNGK46X8QT6X9WQ112",
            "type": "TOKEN",
            "reference": "ALT_CORE_B_AX_31_C",
            "rarity": "COMMON",
            "manaCost": [
                0,
                0
            ],
            "power": [2, 2, 2]
        },
        {
            "name": "Booda",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/ALIZE/CARDS/ALT_ALIZE_B_BR_31/JPG/fr_FR/aeaa2c8586bd00c29c816c089aecaada.png",
            "id": "01HKAFJNR513GHT5HC6HS3AB7B",
            "type": "TOKEN",
            "reference": "ALT_CORE_B_BR_31_C",
            "rarity": "COMMON",
            "manaCost": [
                0,
                0
            ],
            "power": [2, 2, 2]
        },
        {
            "name": "Recrue Ordis",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/ALIZE/CARDS/ALT_ALIZE_B_OR_31/JPG/fr_FR/5e37fd805802d4365dbee203eb56265f.png",
            "id": "01HKAG220G3QEVPG2KQJSM0NR3",
            "type": "TOKEN",
            "reference": "ALT_CORE_B_OR_32_C",
            "rarity": "COMMON",
            "manaCost": [
                0,
                0
            ],
            "power": [1, 1, 1]
        },
        {
            "name": "Maw",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_31/JPG/fr_FR/bdadf50f9179c503a6ddf5e76b98d148.jpg",
            "id": "01HKAFJP5YPC43E267MNH6GQDP",
            "type": "TOKEN",
            "reference": "ALT_CORE_B_YZ_31_C",
            "rarity": "COMMON",
            "manaCost": [
                0,
                0
            ],
            "power": [0, 0, 0]
        },
        {
            "name": "Phalène de Mana",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/ALIZE/CARDS/ALT_ALIZE_B_YZ_47/JPG/fr_FR/22077470d7fa7b231fbf4e614a64eb61.png",
            "id": "67HKAFJP5YPC43E267MNH6GQGF",
            "type": "TOKEN",
            "reference": "ALT_ALIZE_B_YZ_47_C",
            "rarity": "COMMON",
            "manaCost": [
                0,
                0
            ],
            "power": [2, 2, 2]
        }
    ])
    const [deckSize, setDeckSize] = useState(0)
    const [handSize, setHandSize] = useState(0)
    const [lastPlayedCard, setLastPlayedCard] = useState(false)
    const [showReset, setShowReset] = useState(false)
    const [showEndSession, setShowEndSession] = useState(false)
    const [showEvening, setShowEvening] = useState(false)

    const [backgroundChoice, setBackgroundChoice] = useState(10)
    const [showCustomization, setShowCustomization] = useState(false)
    const [is3DEnabled, setIs3DEnabled] = useState(false)
    const [showManaCards, setShowManaCards] = useState(false)
    const [useMobileMode, setUseMobileMode] = useState(false)

    const [useStreamerLayout, setUseStreamerLayout] = useState(false)
    const [lastBoardDataChangeSource, setLastBoardDataChangeSource] = useState(false)

    const isFullscreen = !props.isOpponent && !props.isYourSection && !props.isSpectator
    const isDeckTester = !(props.updateBoardData || props.boardData)

    // Setup

    useEffect(() => {
        reset()
        if (!props.isOpponent || props.isYourSection) {
            const onBoardChanged = (event) => {
                if (event.key === 'board-data') {
                    let newBoardData = event.newValue
                    if (newBoardData) {
                        let data = JSON.parse(newBoardData)
                        setLastBoardDataChangeSource(data.source)
                        if (data.source == (props.isYourSection ? "deck-tester" : "game")) {
                            updateLocalWithBoardData(data)
                        }
                    }
                }
            }
            window.addEventListener('storage', onBoardChanged)
            if (props.isYourSection) {
                let item = window.localStorage.getItem("board-data")
                if (item) {
                    let data = JSON.parse(item)
                    updateLocalWithBoardData(data)
                }
            } else if (!props.isOpponent) {
                let item = window.localStorage.getItem("board-data")
                if (item) {
                    let data = JSON.parse(item)
                    if (data.backgroundChoice) {
                        setBackgroundChoice(data.backgroundChoice)
                    }
                }
            }

            window.addEventListener('keydown', handleKeyDown);
            window.addEventListener('keyup', handleKeyPressed);

            return () => {
                window.removeEventListener('storage', onBoardChanged);
                window.removeEventListener('keydown', handleKeyDown);
                window.removeEventListener('keyyp', handleKeyPressed);
            }
        }/*
        setTimeout(() => {
            updateCardPositions()
        }, 3000)*/
    }, [])

    useEffect(() => {
        if (!props.isOpponent && deckList) {
            let data = {
                deckSize: deck.length,
                handSize: hand.length,
                hero: hero ? { ...hero } : false,
                heroExpedition: [...heroExpedition],
                companionExpedition: [...companionExpedition],
                reserve: [...reserve],
                landmarks: [...landmarks],
                mana: [...mana],
                discard: [...discard],
                lastPlayedCard: lastPlayedCard,
                backgroundChoice: backgroundChoice,
                hand: [...hand],
                source: props.isYourSection ? "game" : "deck-tester",
                deck: [...deck],
                deckList: [...deckList],
                showEvening: showEvening,
                hasUsedTimeTravel: hasUsedTimeTravel
            }


            if (props.isYourSection && props.updateBoardData) {
                props.updateBoardData(data)
            }
            if (lastBoardDataChangeSource == (props.isYourSection ? "game" : "deck-tester")) {
                if ((props.isYourSection && props.updateBoardData) || !props.isYourSection) {
                    // If available, keep timetravel warning
                    let item = window.localStorage.getItem("board-data")
                    if (item) {
                        let dataOld = JSON.parse(item)
                        if (dataOld.hasUsedTimeTravel) {
                            data.hasUsedTimeTravel = dataOld.hasUsedTimeTravel
                            setHasUsedTimeTravel(true)
                        }
                    }
                    window.localStorage.setItem("board-data", JSON.stringify(data));
                }
            }
            setLastBoardDataChangeSource(props.isYourSection ? "game" : "deck-tester")
            /*window.dispatchEvent(new Event('storage'));*/
        }
        updateCardPositions()
    }, [deck, hero, heroExpedition, companionExpedition, reserve, landmarks, mana, discard, hand, lastPlayedCard, backgroundChoice, showEvening])

    useEffect(() => {
        if (props.boardData) {
            const data = props.boardData
            updateLocalWithBoardData(data)
        }
    }, [props.boardData])

    const call = async () => {
        console.log("REPONSE GO")
        let code = "01HKAFJNH8NN6Q06P4R44WNCSM"
        let url = "https://api.altered.gg/cards?qrUrlDetail=" + "https:\/\/qr.altered.gg\/ALT_CORE_B_AX_08_R2"
        // https://api.altered.gg/card_products/01H1NW5YEN8B72T6CXPYDXV5CZ
        const response = await fetch(url);
        console.log("RESPONSE", response)
        const cardResponse = await response.json();
        console.log("RESPONSE 2", cardResponse)
    }

    useEffect(() => {
        if (props.resetVirtualDeck > 0) {
            reset()
        }
    }, [props.resetVirtualDeck])

    useEffect(() => {
        if (props.newTurnVirtualDeck > 0 && !props.useManualMode) {
            endTurn()
        }
    }, [props.newTurnVirtualDeck])

    useEffect(() => {
        if (props.changeBackgroundVirtualDeck > 0) {
            setShowCustomization(true)
        }
    }, [props.changeBackgroundVirtualDeck])

    useEffect(() => {
        if (showEvening == "checking") {
            if (landmarks.length > 2 || reserve.length > 2) {
                setShowEvening(true)
            } else {
                setShowEvening(false)
                newTurn()
            }
        }
    }, [showEvening])

    useEffect(() => {
        if (showEvening === true) {
            if (landmarks.length <= 2 && reserve.length <= 2) {
                setShowEvening(false)
                if (landmarks.length == 0 && reserve.length == 0 && heroExpedition.length == 0 && companionExpedition.length == 0 && hand.length == 6) {
                    return
                }
                newTurn()
            }
        }
    }, [landmarks, reserve])

    const updateLocalWithBoardData = (data) => {
        setHero(data.hero)
        setHeroExpedition(data.heroExpedition)
        setCompanionExpedition(data.companionExpedition)
        setReserve(data.reserve)
        setLandmarks(data.landmarks)
        setMana(data.mana ? data.mana : [])
        setDiscard(data.discard)
        setDeckSize(data.deckSize)
        setDeck(data.deck)
        setHandSize(data.handSize)
        setLastPlayedCard(data.lastPlayedCard)
        setBackgroundChoice(data.backgroundChoice)
        setHand(data.hand)
        setDeckList(data.deckList)
        setDeck(data.deck)
        setShowEvening(data.showEvening)

        if (data.source == "game") {
            if (data.newTurnTimeTravelData) {
                saveTimeTravelData(currentTurn + 1, data.newTurnTimeTravelData)
            }
            // If game got restarted from multiplayer
            if (data.mana.length == 0 && data.hand.length == 0 && data.companionExpedition.length == 0 && data.heroExpedition.length == 0 && data.reserve.length == 0 && data.discard.length == 0) {
                setTimeTravel([{
                    deck: [...data.deck],
                    hero: data.hero ? { ...data.hero } : false,
                    heroExpedition: [],
                    companionExpedition: [],
                    reserve: [],
                    landmarks: [],
                    mana: [],
                    discard: [],
                    hand: [...data.hand]
                }])
                setCurrentTurn(0)

                setHasUsedTimeTravel(false)
                setHideTimeTravelWarning(false)
            }
        }

        if (data.hasUsedTimeTravel) {
            setHasUsedTimeTravel(true)
        } else {
            setHasUsedTimeTravel(false)
        }
    }

    const reset = () => {
        if (!deckList) { return }
        let tmp = []
        let hero = false
        for (let i = 0; i < deckList.length; i++) {
            let c = { ...deckList[i], cardId: uuidv4() }
            if (c.type == "HERO") {
                hero = c
                tmp.splice(i, 1)
            } else {
                tmp.push(c)
            }
        }

        setHero(hero)
        shuffle(tmp)
        setHeroExpedition([])
        setCompanionExpedition([])
        setReserve([])
        setLandmarks([])
        setMana([])
        setDiscard([])
        setCardFocused(false)
        let [deckTmp, handTmp] = drawNewHand(tmp)
        setShowManaCards(false)
        setTimeTravel([{
            deck: [...deckTmp],
            hero: hero ? { ...hero } : false,
            heroExpedition: [],
            companionExpedition: [],
            reserve: [],
            landmarks: [],
            mana: [],
            discard: [],
            hand: [...handTmp]
        }])
        setCurrentTurn(0)

        setHasUsedTimeTravel(false)
        setHideTimeTravelWarning(false)
        setLastPlayedCard(false)
        let item = window.localStorage.getItem("board-data")
        if (item) {
            let data = JSON.parse(item)
            delete data.hasUsedTimeTravel
            window.localStorage.setItem("board-data", JSON.stringify(data));
        }
        setLastBoardDataChangeSource(props.isYourSection ? "game" : "deck-tester")
    }

    useEffect(() => {
        //updateSelectableZone()
        updateFocusedCard()
    }, [cardFocused])

    useEffect(() => {
        updateSelectableZone()
    }, [cardDragged])

    const drawNewHand = (deckTmp) => {
        let handTmp = []
        for (let i = 0; i < 6; i++) {
            handTmp.push(deckTmp.pop())
        }

        setDeck(deckTmp)
        setHand(handTmp)
        return [deckTmp, handTmp]
    }

    const availableMana = mana ? mana.filter((m) => !m.tapped).length : 0

    const previousManaLength = useRef(mana ? mana.length : 0)
    const [triggerManaAnim, setTriggerManaAnim] = useState(false)
    useEffect(() => {
        if (mana.length > previousManaLength.current) {
            setTriggerManaAnim(!triggerManaAnim)
        }
        previousManaLength.current = mana.length
    }, [mana.length])

    // Actions

    const endTurn = () => {
        if (showEvening === true) { return }
        // Move to reserve/discard
        // fleeting token -> discard instatntly
        let newReserve = [...reserve]
        let newDiscard = [...discard]
        let newHero = []
        let newCompanion = []

        for (let i = 0; i < heroExpedition.length; i++) {
            let card = heroExpedition[i]
            delete card.tapped
            let cardToPush = { ...card }

            if (card.tokens != undefined) {
                let tokens = card.tokens
                if (tokens.anchored || tokens.asleep || card.type == "EXPEDITION_PERMANENT") {
                    delete cardToPush.tokens.anchored
                    delete cardToPush.tokens.asleep
                    newHero.push(card)
                } else if (tokens.fleeting) {
                    delete cardToPush.tokens
                    if (card.type != "TOKEN") {
                        newDiscard.push(cardToPush)
                    }
                } else {
                    if (card.type != "TOKEN") {
                        newReserve.push(cardToPush)
                    }
                }
            } else {
                if (card.type == "EXPEDITION_PERMANENT") {
                    newHero.push(cardToPush)
                } else if (card.type != "TOKEN") {
                    newReserve.push(cardToPush)
                }
            }
        }
        for (let i = 0; i < companionExpedition.length; i++) {
            let card = companionExpedition[i]
            delete card.tapped
            let cardToPush = { ...card }

            if (card.tokens != undefined) {
                let tokens = card.tokens
                if (tokens.anchored || tokens.asleep || card.type == "EXPEDITION_PERMANENT") {
                    delete cardToPush.tokens.anchored
                    delete cardToPush.tokens.asleep
                    newCompanion.push(card)
                } else if (tokens.fleeting) {
                    delete cardToPush.tokens
                    if (card.type != "TOKEN") {
                        newDiscard.push(cardToPush)
                    }
                } else {
                    if (card.type != "TOKEN") {
                        newReserve.push(cardToPush)
                    }
                }
            } else {
                if (card.type == "EXPEDITION_PERMANENT") {
                    newCompanion.push(cardToPush)
                } else if (card.type != "TOKEN") {
                    newReserve.push(cardToPush)
                }
            }
        }
        for (let i = 0; i < landmarks.length; i++) {
            delete landmarks[i].tapped
        }
        setHeroExpedition(newHero)
        setCompanionExpedition(newCompanion)

        setCardFocused(false)
        setLandmarks(landmarks)

        setDiscard(newDiscard)
        newReserve.forEach((c) => {
            delete c.tapped
        })
        setReserve(newReserve)

        setShowEvening("checking")
    }

    const newTurn = () => {
        if (props.isOpponent) { return }
        // Untap mana
        let tmpMana = [...mana]
        for (let i = 0; i < tmpMana.length; i++) {
            if (tmpMana[i].tapped != undefined) {
                tmpMana[i].tapped = false
            }
        }

        let newReserve = [...reserve]
        let newDiscard = [...discard]

        let isFenCrowbar = false

        // Untap hero
        let heroTmp = false
        if (hero) {
            heroTmp = { ...hero }
            delete heroTmp.tapped
            setHero(heroTmp)

            if (hero.reference == "ALT_CORE_P_LY_03_C" || hero.reference == "ALT_COREKS_B_LY_03_C" || hero.reference == "ALT_CORE_B_LY_03_C") {
                isFenCrowbar = true
            }
        }


        // Draw 2
        let handTmp = [...hand]
        let deckTmp = [...deck]
        for (let i = 0; i < 2; i++) {
            if (deckTmp.length == 0) {
                deckTmp = [...newDiscard]
                shuffle(deckTmp)
                newDiscard = []
            }
            if (deckTmp.length > 0) {
                if (isFenCrowbar && i == 1) {
                    tmpMana.push(deckTmp.pop())
                } else {
                    handTmp.push(deckTmp.pop())
                }
            }
            if (deckTmp.length == 0) {
                deckTmp = [...newDiscard]
                shuffle(deckTmp)
                newDiscard = []
            }
        }
        setDeck(deckTmp)
        setHand(handTmp)
        setDiscard(newDiscard)
        setReserve(newReserve)
        setMana(tmpMana)
        setCardFocused(false)

        let timeTravelData = {
            deck: [...deckTmp],
            hero: heroTmp ? { ...heroTmp } : false,
            heroExpedition: [...heroExpedition],
            companionExpedition: [...companionExpedition],
            reserve: [...newReserve],
            landmarks: [...landmarks],
            mana: [...tmpMana],
            discard: [...newDiscard],
            hand: [...handTmp]
        }

        saveTimeTravelData(currentTurn + 1, timeTravelData)

        let item = window.localStorage.getItem("board-data")
        if (item) {
            let data = JSON.parse(item)
            data.newTurnTimeTravelData = timeTravelData
            window.localStorage.setItem("board-data", JSON.stringify(data));
        }
    }

    const handleKeyPressed = (e) => {
        if ((props.isYourSection && !props.boardData) || isFullscreen) {
            if (e.key == "Shift") {
                isHoldingShiftRef.current = false
                setQuickTokenModeChanged("released-shift")
            } else if (e.key == "b" || e.key == "a" || e.key == "e" || e.key == "f" || e.key == "d" || e.key == "B" || e.key == "A" || e.key == "E" || e.key == "F" || e.key == "D" || e.key == "t" || e.key == "T" || e.key == "x" || e.key == "X") {
                quickTokenModeRef.current = false
                setQuickTokenModeChanged("released-token")
            }
            if (props.boardData || props.isYourSection) { return }
            if (e.key == "Escape") {
                setCardFocused(false)
            }
        }
    }

    const handleNewTurnKeyPressed = (e) => {
        if (isFullscreen && e.key === " ") {
            endTurn();
        }
    }

    const isHoldingShiftRef = useRef(false)
    const [quickTokenModeChanged, setQuickTokenModeChanged] = useState(false)

    const handleKeyDown = (e) => {
        if ((props.isYourSection && !props.boardData) || isFullscreen) {
            if (e.key === 'b' || e.key === 'B') {
                quickTokenModeRef.current = "boost"
                setQuickTokenModeChanged("boost")
            } else if (e.key === 'a' || e.key == "A") {
                quickTokenModeRef.current = "anchored"
                setQuickTokenModeChanged("anchored")
            } else if (e.key === 'e' || e.key == "E") {
                quickTokenModeRef.current = "asleep"
                setQuickTokenModeChanged("asleep")
            } else if (e.key === 'f' || e.key == "F") {
                quickTokenModeRef.current = "fleeting"
                setQuickTokenModeChanged("fleeting")
            } else if (e.key === 'd' || e.key === 'D') {
                quickTokenModeRef.current = "divers"
                setQuickTokenModeChanged("divers")
            } else if (e.key === 't' || e.key === 'T') {
                quickTokenModeRef.current = "exhaust"
                setQuickTokenModeChanged("exhaust")
            }
            else if (e.key === 'x' || e.key === 'X') {
                quickTokenModeRef.current = "clear"
                setQuickTokenModeChanged("clear")
            }
            if (e.key === 'Shift') {
                isHoldingShiftRef.current = true
                setQuickTokenModeChanged("shift")
            }
        }
    }

    const updateFocusedCard = () => {
        if (cardFocused.source == "hero-expedition") {
            let tmp = [...heroExpedition]
            tmp[cardFocused.index] = cardFocused.card
            tmp[cardFocused.index].tapped = heroExpedition[cardFocused.index].tapped
            setHeroExpedition(tmp)
        } else if (cardFocused.source == "companion-expedition") {
            let tmp = [...companionExpedition]
            tmp[cardFocused.index] = cardFocused.card
            tmp[cardFocused.index].tapped = companionExpedition[cardFocused.index].tapped
            setCompanionExpedition(tmp)
        } else if (cardFocused.source == "reserve") {
            let tmp = [...reserve]
            tmp[cardFocused.index] = cardFocused.card
            setReserve(tmp)
        } else if (cardFocused.source == "landmarks") {
            let tmp = [...landmarks]
            tmp[cardFocused.index] = cardFocused.card
            tmp[cardFocused.index].tapped = landmarks[cardFocused.index].tapped
            setLandmarks(tmp)
        } else if (cardFocused.source == "hero") {
            let tmp = { ...cardFocused.card }
            tmp.tapped = hero.tapped
            setHero(tmp)
        }
    }

    const sectionClicked = (section) => {
        if (section == cardDragged.source) { return }
        let card = { ...cardDragged }
        if (card.source == "hand") {
            playCardFromHand(section)
        } else if (card.source == "reserve") {
            playCardFromReserve(section)
        } else if (card.source == "discard") {
            playCardFromDiscard(section)
        } else if (card.source == "tokens") {
            if (section == "hero-expedition") {
                setHeroExpedition([card.card, ...heroExpedition])
            } else if (section == "companion-expedition") {
                setCompanionExpedition([...companionExpedition, card.card])
            }
            setCardDragged(false)
        } else if (card.source == "hero-expedition" || card.source == "companion-expedition") {
            let c = card.card
            delete c.tapped
            if (card.card.type != "TOKEN") {
                if (section == "reserve") {
                    setReserve([...reserve, c])
                } else if (section == "discard") {
                    delete c.tokens
                    setDiscard([...discard, c])
                } else if (section == "mana") {
                    setMana([...mana, c])
                }
            }
            let tmpHero = [...heroExpedition]
            let tmpCompanion = [...companionExpedition]
            if (section == "hero-expedition") {
                tmpHero.unshift(c)
            } else if (section == "companion-expedition") {
                tmpCompanion.push(c)
            }
            if (card.source == "hero-expedition") {
                tmpHero.splice(card.index, 1)
            } else {
                tmpCompanion.splice(card.index, 1)
            }
            setHeroExpedition(tmpHero)
            setCompanionExpedition(tmpCompanion)
            setCardDragged(false)
            showLastPlayedCard(card.card, card.card, card.source, section)
        } else if (card.source == "landmarks") {
            let c = card.card
            delete c.tapped
            if (section == "discard") {
                delete c.tokens
                setDiscard([...discard, c])
            } else if (section == "mana") {
                setMana([...mana, c])
            }
            setLandmarks([...landmarks].toSpliced(card.index, 1))
            setCardDragged(false)
            showLastPlayedCard(card.card, card.card, "landmarks", section)
        } else if (section == "discard" && card.source == "mana") {
            let c = card.card
            delete c.tapped
            delete c.tokens
            setDiscard([...discard, c])
            setMana([...mana].toSpliced(card.index, 1))
            setCardDragged(false)
        }
    }

    const playCardFromHand = (section) => {
        let card = { ...cardDragged }
        if (section == "hero-expedition") {
            setHeroExpedition([card.card, ...heroExpedition])
            tapXMana(card.card.manaCost[0])
        } else if (section == "companion-expedition") {
            setCompanionExpedition([...companionExpedition, card.card])
            tapXMana(card.card.manaCost[0])
        } else if (section == "mana") {
            setMana([...mana, card.card])
        } else if (section == "reserve") {
            setReserve([...reserve, card.card])
            if (card.card.type == "SPELL") {
                tapXMana(card.card.manaCost[0])
            }
        } else if (section == "landmarks") {
            setLandmarks([...landmarks, card.card])
            tapXMana(card.card.manaCost[0])
        } else if (section == "discard") {
            setDiscard([...discard, card.card])
            if (card.card.type == "SPELL") {
                tapXMana(card.card.manaCost[0])
            }
        }
        setHand([...hand].toSpliced(card.index, 1))
        showLastPlayedCard(card.card, card.card, "hand", section)
        setCardDragged(false)
    }

    const playCardFromReserve = (section) => {
        let card = { ...cardDragged }
        if (section == "hero-expedition") {
            let newCard = { ...card.card, tokens: { fleeting: 1 } }
            if (card.card.tokens != undefined) {
                newCard = { ...card.card, tokens: { ...card.card.tokens, fleeting: 1 } }
            }
            setHeroExpedition([newCard, ...heroExpedition])
            tapXMana(card.card.manaCost[1])
        } else if (section == "companion-expedition") {
            let newCard = { ...card.card, tokens: { fleeting: 1 } }
            if (card.card.tokens != undefined) {
                newCard = { ...card.card, tokens: { ...card.card.tokens, fleeting: 1 } }
            }
            setCompanionExpedition([...companionExpedition, newCard])
            tapXMana(card.card.manaCost[1])
        } else if (section == "mana") {
            setMana([...mana, card.card])
        } else if (section == "landmarks") {
            setLandmarks([...landmarks, card.card])
            tapXMana(card.card.manaCost[1])
        } else if (section == "discard") {
            let c = card.card
            delete c.tapped
            delete c.tokens
            setDiscard([...discard, c])
            if (card.card.type == "SPELL") {
                tapXMana(card.card.manaCost[1])
            }
        }
        setReserve([...reserve].toSpliced(card.index, 1))
        if (section != "discard" || card.card.type == "SPELL") {
            showLastPlayedCard(card.card, card.card, "reserve", section)
        }
        setCardDragged(false)
    }

    const playCardFromDiscard = (section) => {
        let card = { ...cardDragged }
        if (section == "hero-expedition") {
            setHeroExpedition([card.card, ...heroExpedition])
        } else if (section == "companion-expedition") {
            setCompanionExpedition([...companionExpedition, card.card])
        } else if (section == "mana") {
            setMana([...mana, card.card])
        } else if (section == "landmarks") {
            setLandmarks([...landmarks, card.card])
        } else if (section == "reserve") {
            setReserve([...reserve, card.card])
        }
        showLastPlayedCard(card.card, discard[0], "discard", section)
        setDiscard([...discard].toSpliced(card.index, 1))
        setCardDragged(false)
    }

    const discardFocusedCard = () => {
        let source = cardFocused.source
        if (source == "hero-expedition") {
            setHeroExpedition([...heroExpedition].toSpliced(cardFocused.index, 1))
        } else if (source == "companion-expedition") {
            setCompanionExpedition([...companionExpedition].toSpliced(cardFocused.index, 1))
        } else if (source == "mana") {
            setMana([...mana].toSpliced(cardFocused.index, 1))
        } else if (source == "reserve") {
            setReserve([...reserve].toSpliced(cardFocused.index, 1))
        } else if (source == "landmarks") {
            setLandmarks([...landmarks].toSpliced(cardFocused.index, 1))
        } else if (source == "hand") {
            setHand([...hand].toSpliced(cardFocused.index, 1))
        }
        let card = cardFocused.card
        delete card.tokens
        delete card.tapped
        if (card.type != "TOKEN") {
            setDiscard([...discard, card])
        }
        if (source == "reserve" && cardFocused.card.type == "SPELL") {
            showLastPlayedCard(cardFocused.card, cardFocused.card, source, "discard")
        }
        setCardFocused(false)
    }

    const returnToHandFocusedCard = () => {
        let source = cardFocused.source
        if (source == "hero-expedition") {
            setHeroExpedition([...heroExpedition].toSpliced(cardFocused.index, 1))
        } else if (source == "companion-expedition") {
            setCompanionExpedition([...companionExpedition].toSpliced(cardFocused.index, 1))
        } else if (source == "mana") {
            setMana([...mana].toSpliced(cardFocused.index, 1))
        } else if (source == "reserve") {
            setReserve([...reserve].toSpliced(cardFocused.index, 1))
        } else if (source == "landmarks") {
            setLandmarks([...landmarks].toSpliced(cardFocused.index, 1))
        }
        let card = cardFocused.card
        delete card.tokens
        delete card.tapped
        setHand([...hand, card])
        showLastPlayedCard(cardFocused.card, cardFocused.card, source, "hand")
        setCardFocused(false)
    }

    const tapXMana = (x) => {
        let manaTmp = [...mana]
        let manaTapped = 0
        for (let i = mana.length - 1; i >= 0 && manaTapped < x; i--) {
            if (manaTmp[i].tapped == undefined || manaTmp[i].tapped == false) {
                manaTmp[i] = { ...manaTmp[i], tapped: true }
                manaTapped++
            }
        }
        setMana(manaTmp)
    }

    const untapXMana = (x) => {
        let manaTmp = [...mana]
        let manaUntapped = 0
        for (let i = mana.length - 1; i >= 0 && manaUntapped < x; i--) {
            if (manaTmp[i].tapped) {
                manaTmp[i] = { ...manaTmp[i], tapped: false }
                manaUntapped++
            }
        }
        setMana(manaTmp)
    }

    const tapFocusedCard = () => {
        let source = cardFocused.source
        if (source == "hero-expedition") {
            let tmp = [...heroExpedition]
            let c = tmp[cardFocused.index]
            tmp[cardFocused.index] = { ...c, tapped: c.tapped == undefined ? true : !c.tapped }
            setHeroExpedition(tmp)
        } else if (source == "companion-expedition") {
            let tmp = [...companionExpedition]
            let c = tmp[cardFocused.index]
            tmp[cardFocused.index] = { ...c, tapped: c.tapped == undefined ? true : !c.tapped }
            setCompanionExpedition(tmp)
        } else if (source == "landmarks") {
            let tmp = [...landmarks]
            let c = tmp[cardFocused.index]
            tmp[cardFocused.index] = { ...c, tapped: c.tapped == undefined ? true : !c.tapped }
            setLandmarks(tmp)
        } else if (source == "hero") {
            setHero({ ...hero, tapped: hero.tapped == undefined ? true : !hero.tapped })
        } else if (source == "reserve") {
            let tmp = [...reserve]
            let c = tmp[cardFocused.index]
            tmp[cardFocused.index] = { ...c, tapped: c.tapped == undefined ? true : !c.tapped }
            setReserve(tmp)
        }
    }

    const updateSelectableZone = () => {
        let card = cardDragged
        if (card == false) {
            setSelectableZone({ any: false, heroExpedition: false, companionExpedition: false, mana: false, reserve: false, landmarks: false, discard: false })
            return
        }
        const isPlayable = (source) => {
            return source == "hand" || source == "reserve" || source == "discard"
        }
        let newValue = {
            any: false,
            heroExpedition: (isPlayable(card.source) && (card.card.type === "CHARACTER" || card.card.type === "EXPEDITION_PERMANENT")) || card.source == "tokens" || card.source == "companion-expedition",
            companionExpedition: (isPlayable(card.source) && (card.card.type === "CHARACTER" || card.card.type === "EXPEDITION_PERMANENT")) || card.source == "tokens" || card.source == "hero-expedition",
            mana: card.source != "hero" && card.source != "tokens" && card.source != "mana",
            reserve: card.source == "hand" || card.source == "discard" || card.source == "hero-expedition" || card.source == "companion-expedition",
            landmarks: isPlayable(card.source) && card.card.type == "PERMANENT",
            discard: card.source != "discard" && card.source != "hero" && card.source != "tokens"
        }
        newValue.any = newValue.heroExpedition || newValue.companionExpedition || newValue.mana || newValue.reserve || newValue.landmarks || newValue.discard
        setSelectableZone(newValue)
    }

    const setNewCardFocused = (cardInfo) => {
        if (props.isOpponent || props.isSpectator) {
            //props.clickVirtualCard(cardInfo.card)
        } else {
            setCardFocused({ source: cardInfo.source, card: { ...cardInfo.card }, index: cardInfo.index })
        }
    }

    const setNewCardDragged = (cardInfo) => {
        if (quickTokenModeRef.current || isHoldingShiftRef.current) {
            const applyToCard = (card) => {
                if (!card.tokens) {
                    card.tokens = {}
                }
                if (quickTokenModeRef.current == "boost" && isHoldingShiftRef.current) {
                    card.tokens.boost = card.tokens.boost ? card.tokens.boost - 1 : 0
                    if (card.tokens.boost <= 0) {
                        delete card.tokens.boost
                    }
                } else if (quickTokenModeRef.current == "boost") {
                    card.tokens.boost = card.tokens.boost ? card.tokens.boost + 1 : 1
                } else if (quickTokenModeRef.current == "anchored") {
                    card.tokens.anchored = card.tokens.anchored ? false : true
                } else if (quickTokenModeRef.current == "asleep") {
                    card.tokens.asleep = card.tokens.asleep ? false : true
                } else if (quickTokenModeRef.current == "fleeting") {
                    card.tokens.fleeting = card.tokens.fleeting ? false : true
                } else if (quickTokenModeRef.current == "divers" && isHoldingShiftRef.current) {
                    card.tokens.divers = card.tokens.divers ? card.tokens.divers - 1 : 0
                    if (card.tokens.divers <= 0) {
                        delete card.tokens.divers
                    }
                } else if (quickTokenModeRef.current == "divers") {
                    card.tokens.divers = card.tokens.divers ? card.tokens.divers + 1 : 1
                } else if (quickTokenModeRef.current == "exhaust") {
                    card.tapped = card.tapped ? false : true
                } else if (quickTokenModeRef.current == "clear") {
                    card.tokens = {}
                }
                return card
            }

            if (cardInfo.source == "hero-expedition") {
                let tmp = [...heroExpedition]
                let c = tmp[cardInfo.index]
                applyToCard(c)
                tmp[cardInfo.index] = c
                setHeroExpedition(tmp)
            } else if (cardInfo.source == "companion-expedition") {
                let tmp = [...companionExpedition]
                let c = tmp[cardInfo.index]
                applyToCard(c)
                tmp[cardInfo.index] = c
                setCompanionExpedition(tmp)
            } else if (cardInfo.source == "landmarks") {
                let tmp = [...landmarks]
                let c = tmp[cardInfo.index]
                applyToCard(c)
                tmp[cardInfo.index] = c
                setLandmarks(tmp)
            } else if (cardInfo.source == "hero") {
                let c = { ...hero }
                applyToCard(c)
                setHero(c)
            } else if (cardInfo.source == "reserve") {
                let tmp = [...reserve]
                let c = tmp[cardInfo.index]
                applyToCard(c)
                tmp[cardInfo.index] = c
                setReserve(tmp)
            }

            return false
        } else {
            if (cardInfo.source != "hero") {
                setCardDragged({ source: cardInfo.source, card: { ...cardInfo.card }, index: cardInfo.index })
            }
            return true
        }
    }

    const draw = (x) => {
        let handTmp = [...hand]
        let deckTmp = [...deck]
        for (let i = 0; i < x; i++) {
            if (deckTmp.length > 0) {
                handTmp.push(deckTmp.pop())
            }
            if (deckTmp.length == 0) {
                deckTmp = [...discard]
                shuffle(deckTmp)
                setDiscard([])
            }
        }
        setDeck(deckTmp)
        setHand(handTmp)
    }

    const resupply = (x) => {
        let reserveTmp = [...reserve]
        let deckTmp = [...deck]
        for (let i = 0; i < x; i++) {
            if (deckTmp.length > 0) {
                reserveTmp.push(deckTmp.pop())
            }
            if (deckTmp.length == 0) {
                deckTmp = [...discard]
                shuffle(deckTmp)
                setDiscard([])
            }
        }
        setDeck(deckTmp)
        setReserve(reserveTmp)
    }

    const showLastPlayedCard = (card, source, sourceSection, destination) => {
        if (!props.isOpponent) {
            let lastPlayed = { ...card }
            lastPlayed.source = source
            lastPlayed.sourceSection = sourceSection
            lastPlayed.isCardHidden = destination == "mana"
            setLastPlayedCard(lastPlayed)
            /*if (props.clickVirtualCard) {
                props.clickVirtualCard({ ...card }, true)
            }*/
        }
    }

    const removeFocusedCardFromSource = () => {
        let source = cardFocused.source
        if (source == "hero-expedition") {
            setHeroExpedition([...heroExpedition].toSpliced(cardFocused.index, 1))
        } else if (source == "companion-expedition") {
            setCompanionExpedition([...companionExpedition].toSpliced(cardFocused.index, 1))
        } else if (source == "mana") {
            setMana([...mana].toSpliced(cardFocused.index, 1))
        } else if (source == "reserve") {
            setReserve([...reserve].toSpliced(cardFocused.index, 1))
        } else if (source == "landmarks") {
            setLandmarks([...landmarks].toSpliced(cardFocused.index, 1))
        } else if (source == "hand") {
            setHand([...hand].toSpliced(cardFocused.index, 1))
        }
    }

    const sendToDeck = (action) => {
        removeFocusedCardFromSource()
        let card = cardFocused.card
        delete card.tokens
        delete card.tapped
        if (card.type != "TOKEN") {
            /*if (cardFocused.source != "hand" && cardFocused.source != "mana") {
                showLastPlayedCard(cardFocused.card)
            }*/
            let tmp = [...deck]
            if (action == "top") {
                tmp.push(card)
            } else if (action == "bottom") {
                tmp.unshift(card)
            } else if (action == "shuffle") {
                tmp.push(card)
                shuffle(tmp)
            }
            setDeck(tmp)
        }
        setCardFocused(false)
    }

    const extraDeckActions = (action) => {
        if (action == "top-to-mana") {
            let deckTmp = [...deck]
            if (deckTmp.length > 0) {
                let card = deckTmp.pop()
                card.tapped = true
                let tmpMana = [...mana]
                tmpMana.push({ ...card })
                setMana(tmpMana)
            }
            if (deckTmp.length == 0) {
                deckTmp = [...discard]
                shuffle(deckTmp)
                setDiscard([])
            }
            setDeck(deckTmp)
        } else if (action == "reserve-exhausted") {
            let deckTmp = [...deck]
            if (deckTmp.length > 0) {
                let card = deckTmp.pop()
                card.tapped = true
                let tmpReserve = [...reserve]
                card.tapped = true
                tmpReserve.push({ ...card })
                setReserve(tmpReserve)
            }
            if (deckTmp.length == 0) {
                deckTmp = [...discard]
                shuffle(deckTmp)
                setDiscard([])
            }
            setDeck(deckTmp)
        }
    }

    const exhaust = (cardInfo) => {
        if (cardInfo.source == "hero-expedition") {
            let tmp = [...heroExpedition]
            let c = tmp[cardInfo.index]
            c.tapped = !c.tapped
            tmp[cardInfo.index] = c
            setHeroExpedition(tmp)
        } else if (cardInfo.source == "companion-expedition") {
            let tmp = [...companionExpedition]
            let c = tmp[cardInfo.index]
            c.tapped = !c.tapped
            tmp[cardInfo.index] = c
            setCompanionExpedition(tmp)
        } else if (cardInfo.source == "landmarks") {
            let tmp = [...landmarks]
            let c = tmp[cardInfo.index]
            c.tapped = !c.tapped
            tmp[cardInfo.index] = c
            setLandmarks(tmp)
        } else if (cardInfo.source == "hero") {
            let c = { ...hero }
            c.tapped = !hero.tapped
            setHero(c)
        }
    }

    const zoomCard = (z) => {
        if (props.zoomVirtualCard) {
            if (cardDragged == false) {
                props.zoomVirtualCard(z)
            } else {
                props.zoomVirtualCard(false)
            }
        }
    }


    const support = (cardInfo) => {
        setReserve([...reserve].toSpliced(cardInfo.index, 1))

        let card = cardInfo.card
        delete card.tokens
        delete card.tapped
        if (card.type != "TOKEN") {
            setDiscard([...discard, card])
        }
        showLastPlayedCard(cardInfo.card, cardInfo.card, "reserve")
        if (props.zoomVirtualCard) {
            props.zoomVirtualCard(false)
        }
    }

    const updateCardPositions = () => {
        if (props.isSpectator || props.isOpponent) {
            let newCardsPositions = {}
            hand.forEach((c) => {
                let e = document.getElementById("card-id-" + c.cardId + "-" + virtualBoardId)
                if (e) {
                    newCardsPositions[c.cardId] = e.getBoundingClientRect()
                }
            })
            heroExpedition.forEach((c) => {
                let e = document.getElementById("card-id-" + c.cardId + "-" + virtualBoardId)
                if (e) {
                    newCardsPositions[c.cardId] = e.getBoundingClientRect()
                }
            })
            companionExpedition.forEach((c) => {
                let e = document.getElementById("card-id-" + c.cardId + "-" + virtualBoardId)
                if (e) {
                    newCardsPositions[c.cardId] = e.getBoundingClientRect()
                }
            })
            reserve.forEach((c) => {
                let e = document.getElementById("card-id-" + c.cardId + "-" + virtualBoardId)
                if (e) {
                    newCardsPositions[c.cardId] = e.getBoundingClientRect()
                }
            })
            landmarks.forEach((c) => {
                let e = document.getElementById("card-id-" + c.cardId + "-" + virtualBoardId)
                if (e) {
                    newCardsPositions[c.cardId] = e.getBoundingClientRect()
                }
            })
            setTimeout(() => {
                cardsPositions.current = newCardsPositions
            }, 150)
        }
    }

    if (props.isYourSection && !deckList && !deck) {
        return (<Loading />)
    }



    return (
        <div class={"virtual-board " + (props.isSpectator ? "spectator " : "") + (props.isOpponent ? "opponent " : "") + (useMobileMode ? " mobile-mode " : "") + (props.isYourSection ? "your-section " : "") + (props.isOpponent || props.isYourSection ? " player-section" : " fullscreen") + (useStreamerLayout || props.useStreamerLayout ? " streamer-layout" : "") + (props.useMiniCards ? " use-mini-cards" : "")} tabIndex={0} onKeyUp={handleNewTurnKeyPressed}
            onMouseLeave={() => {
                if (!props.isDigitalOnly) {
                    setCardDragged(false)
                }
            }}>
            <div class="background-mask"><div class="night"></div><div class={"background" + (" background-choice-" + backgroundChoice) + " w-100 h-100"} ></div></div>
            <div class="d-flex flex-row">
                <LeftBar component={
                    <div class="left-side-bar h-100 d-flex flex-column justify-content-between">
                        <div></div>
                        <GlassBackground />
                        <div class="d-flex flex-column justify-content-around">
                            {/*<LeftSideButton icon="mic-mute" clicked={} />*/}
                            {/*<LeftSideButton icon="cube" clicked={() => { setIs3DEnabled(!is3DEnabled) }} selected={is3DEnabled} description={t("virtual_board.menu.button_3d_mode.description")} />*/}
                            <LeftSideButton icon="options" clicked={() => { setShowCustomization(true) }} description={t("virtual_board.menu.button_options.description")} />
                            <LeftSideButton icon="connect" clicked={() => { window.open('/play', '_blank', 'noopener,noreferrer'); }} description={t("virtual_board.menu.button_online.description")} />
                            <LeftSideButton icon="refresh" clicked={() => { setShowReset(true) }} description={t("virtual_board.menu.button_restart.description")} />
                            <DarkMode />
                        </div>
                        <div class="d-flex flex-column justify-content-around">
                            {<LeftSideButton icon="exit" clicked={() => setShowEndSession(true)} description={t("virtual_board.menu.button_leave.description")} />}
                        </div>
                    </div>
                } />
                <div class={("d-flex flex-column board") + (selectableZone.any ? " waiting-selection" : "") + (is3DEnabled ? " p-3D" : "") + (showEvening === true ? " evening" : "")}>
                    <div className='position-absolute top-0 start-0 w-100 h-100' onMouseUp={() => setCardDragged(false)}></div>
                    <div class="d-flex flex-column board-sections">
                        <div class="d-flex flex-row">
                            <BoardSection sectionClicked={sectionClicked} sectionTitle="hero-expedition" selectable={selectableZone.heroExpedition} component={<div class="scrollable overlaping mini-cards">
                                {heroExpedition.map((card, i) => {
                                    return (<Card cardDragged={cardDragged}
                                        virtualBoardId={virtualBoardId}
                                        focusCard={() => setNewCardFocused({ source: "hero-expedition", card: card, index: i })}
                                        onClick={() => { return setNewCardDragged({ source: "hero-expedition", card: card, index: i }) }} key={card.cardId} card={card} zoomVirtualCard={zoomCard}
                                        quickActionButton={() => exhaust({ source: "hero-expedition", card: card, index: i })}
                                    />)
                                })}
                            </div>} />
                            <div class="board-section hero single mini-cards">
                                {hero != false && (<Card card={hero}
                                    virtualBoardId={virtualBoardId}
                                    focusCard={() => { setNewCardFocused({ source: "hero", card: hero, index: 0 }) }
                                    }
                                    onClick={() => { return setNewCardDragged({ source: "hero", card: hero, index: 0 }) }}
                                    quickActionButton={() => exhaust({ source: "hero", card: hero, index: 0 })}
                                    zoomVirtualCard={zoomCard} />)}
                            </div>
                            <BoardSection sectionClicked={sectionClicked} sectionTitle="companion-expedition" selectable={selectableZone.companionExpedition} component={<div class="scrollable overlaping mini-cards">
                                {companionExpedition.map((card, i) => {
                                    return (<Card cardDragged={cardDragged}
                                        virtualBoardId={virtualBoardId}
                                        focusCard={() => setNewCardFocused({ source: "companion-expedition", card: card, index: i })}
                                        onClick={() => { return setNewCardDragged({ source: "companion-expedition", card: card, index: i }) }} key={card.cardId} card={card} zoomVirtualCard={zoomCard}
                                        quickActionButton={() => exhaust({ source: "companion-expedition", card: card, index: i })}
                                    />)
                                })}
                            </div>} />
                        </div>
                        <div class="d-flex flex-row">
                            <BoardSection sectionClicked={sectionClicked} sectionTitle="reserve" selectable={selectableZone.reserve} component={<div class="scrollable overlaping mini-cards">
                                {reserve.map((card, i) => {
                                    return (<Card missingMana={availableMana < card.manaCost[1]} cardDragged={cardDragged}
                                        virtualBoardId={virtualBoardId}
                                        focusCard={() => setNewCardFocused({ source: "reserve", card: card, index: i })}
                                        onClick={() => { return setNewCardDragged({ source: "reserve", card: card, index: i }) }} key={card.cardId} card={card} zoomVirtualCard={zoomCard}
                                        quickActionButton={() => support({ source: "reserve", card: card, index: i })}
                                    />)
                                })}
                            </div>} />
                            <BoardSection sectionClicked={sectionClicked} sectionTitle="landmarks" selectable={selectableZone.landmarks} component={<div class="scrollable overlaping mini-cards">
                                {landmarks.map((card, i) => {
                                    return (<Card cardDragged={cardDragged}
                                        virtualBoardId={virtualBoardId}
                                        focusCard={() => setNewCardFocused({ source: "landmarks", card: card, index: i })}
                                        onClick={() => { return setNewCardDragged({ source: "landmarks", card: card, index: i }) }} key={card.cardId} card={card} zoomVirtualCard={zoomCard}
                                        quickActionButton={() => exhaust({ source: "landmarks", card: card, index: i })}
                                    />)
                                })}
                            </div>} />
                        </div>
                        <div class="d-flex flex-row">
                            <BoardSection sectionClicked={sectionClicked} sectionTitle="mana" selectable={selectableZone.mana} component={<div class="scrollable overlaping">
                                <div class="pile-count mana-count undraggable">
                                    <p>{availableMana}/{mana.length}</p>
                                    <Button onClick={() => {
                                        setShowManaCards(!showManaCards)
                                        setShowManaPanel(true)
                                    }}>{showManaCards ? t("virtual_board.board.hide") : t("virtual_board.board.show")}</Button>
                                    <div class="mana-change pe-none d-none flex-row">
                                        <Button onClick={() => tapXMana(1)}>-</Button>
                                        <Button onClick={() => untapXMana(1)}>+</Button>
                                    </div>
                                </div>
                                {mana.map((card, i) => {
                                    if (showManaCards) {
                                        return (<Card cardDragged={cardDragged}
                                            virtualBoardId={virtualBoardId}
                                            focusCard={() => setNewCardFocused({ source: "mana", card: card, index: i })}
                                            onClick={() => { return setNewCardDragged({ source: "mana", card: card, index: i }) }} key={card.cardId} card={card} zoomVirtualCard={zoomCard} />)
                                    } else {
                                        return (<div id={"card-id-" + card.cardId + "-" + virtualBoardId} key={card.cardId} class={("tapped-wrapper mana-hidden") + (mana[i].tapped ? " tapped" : "")}><div class="board-card" onClick={() => {
                                            let manaTmp = [...mana]
                                            manaTmp[i] = { ...manaTmp[i], tapped: manaTmp[i].tapped == undefined ? true : !manaTmp[i].tapped }
                                            setMana(manaTmp)
                                        }} >
                                            <ImageMemo src={require('./Assets/card-back.png')} className="card-image" />
                                        </div></div>)
                                    }
                                })
                                }
                                {false && mana.length > 0 && (<div class="add-mana-animation" key={triggerManaAnim}><ImageMemo src={require('./Assets/card-back.png')} className="card-image" />
                                </div>)}
                            </div>} />
                            <div class="board-section deck single">
                                {!props.isOpponent || props.isYourSection ? (
                                    <div class="board-card">
                                        <DeckVisual deckLength={deck.length}>
                                            <div class="interaction d-flex flex-column p-1">
                                                <a onClick={() => { draw(1) }}>{t("virtual_board.board.deck.draw")}</a>
                                                <a onClick={() => { resupply(1) }}>{t("virtual_board.board.deck.resupply")}</a>
                                            </div>
                                            <DeckExtra extraDeckActions={extraDeckActions} />
                                        </DeckVisual>
                                    </div>
                                ) : (
                                    <div class="board-card">
                                        <DeckVisual deckLength={deck.length} />
                                    </div>
                                )}
                            </div>
                            <BoardSection sectionClicked={sectionClicked} sectionTitle="discard" selectable={selectableZone.discard} single={true} component={<div class="w-100 h-100 position-relative">
                                {discard.length > 1 && (<div class="board-card old-discard"><img src={discard[discard.length - 2].image} /></div>)}
                                {discard.length > 0 && (<Card virtualBoardId={virtualBoardId} cardDragged={cardDragged} card={discard[discard.length - 1]} onClick={() => { setShowDiscard(true) }} zoomVirtualCard={zoomCard} />)}
                                <div class="pile-count undraggable"><p>{discard.length}</p></div>
                            </div>} />
                        </div>
                    </div>

                    {hasUsedTimeTravel && !hideTimeTravelWarning && !isDeckTester && (<p class="position-absolute time-travel-warning top-0 start-50 translate-middle-x" onClick={() => setHideTimeTravelWarning(true)}>{t("virtual_board.menu.time_travel.has_been_used")}</p>)}

                    <div class="tokens-panel">
                        <div class="blurred-background shadowed">
                        </div>
                        <div class="tokens-row d-flex flex-row-reverse">
                            {tokens.map((card, i) => {
                                return (
                                    <div key={card.id}><Button onClick={() => {
                                        let t = { ...card }
                                        t.cardId = uuidv4()
                                        setNewCardDragged({ source: "tokens", card: t, index: i })
                                    }} >{card.name /*+ " " + card.power[0] + "/" + card.power[1] + "/" + card.power[2]*/}</Button></div>
                                )
                            })}
                        </div>
                        <div class="tokens-button small-round-button">
                            <p class="m-0">+</p>
                        </div>
                    </div>

                    {(!props.isOpponent || props.isYourSection || props.isSpectator) && hand ? (<div class="hand d-flex flex-row justify-content-center" onPointerUp={() => setCardDragged(false)}>
                        {hand.map((card, i) => {
                            return (<Card missingMana={availableMana < card.manaCost[0] || mana.length < 3} cardDragged={cardDragged} className={"shadowed " + ((cardFocused && cardFocused.card.cardId == card.cardId) ? "selected" : "")}
                                focusCard={() => setNewCardFocused({ source: "hand", card: card, index: i })}
                                virtualBoardId={virtualBoardId}
                                onClick={() => {
                                    return setNewCardDragged({ source: "hand", card: card, index: i })
                                }} key={card.cardId} card={card} zoomVirtualCard={(z) => {
                                    if (!props.isYourSection || props.isSpectator) {
                                        zoomCard(z)
                                    }
                                }}
                            />)
                        })}
                    </div>) : (<div class="hand d-flex flex-row justify-content-center">
                        {hand.map((card, i) => {
                            return (<div class="tapped-wrapper"><div class="shadowed board-card" id={card.cardId ? "card-id-" + card.cardId + "-" + virtualBoardId : "card-id-none"}>
                                <img src={require('./Assets/card-back.png')} class="card-image" />
                            </div></div>)
                        })}
                    </div>)}
                    {(!props.isOpponent || props.isYourSection) && (
                        <div class="evening-section">
                            <div class="blurred-background"></div>
                            <p class="mb-1">{t("virtual_board.board.night.content")}</p>
                            <Button onClick={() => { setShowEvening(false); newTurn() }}>{t("virtual_board.board.night.ignore_button")}</Button>
                        </div>)}
                </div>
                {cardFocused && (
                    <div class="card-focused d-flex flex-row justify-content-center align-items-center">
                        <div class="background w-100 h-100 position-absolute" onClick={() => { setCardFocused(false); setCardDragged(false) }}></div>
                        <div class="d-flex flex-column justify-content-start m-1">
                            <Card className="m-0" card={cardFocused.card} />
                        </div>
                        <div class="d-flex flex-column justify-content-start m-1">
                            {(cardFocused.source == "hero-expedition" || cardFocused.source == "companion-expedition" || cardFocused.source == "landmarks" || cardFocused.source == "hero" || (cardFocused.source == "reserve")) && (<Button class="m-1" onClick={() => { tapFocusedCard(); setCardFocused(false) }}>{t("virtual_board.focus.exhaust")}</Button>)}
                            <div class="d-flex flex-row buttons-lign">
                                {cardFocused.source != "hero" && cardFocused.source != "tokens" && (<Button class="m-1 flex-fill" onClick={() => discardFocusedCard()}>{t("virtual_board.focus.discard")}</Button>)}
                                {cardFocused.source != "hand" && cardFocused.source != "hero" && cardFocused.card.type != "TOKEN" && (<Button class="m-1 flex-fill" onClick={() => returnToHandFocusedCard()}>{t("virtual_board.focus.to_hand")}</Button>)}
                            </div>
                            {cardFocused.source != "hero" && (
                                <div class="tmp-button">
                                    <p class="mb-0">{t("virtual_board.focus.deck_return.content")}</p>
                                    <div class="d-flex flex-row align-items-center">
                                        <button class="m-1 flex-fill" onClick={() => sendToDeck("top")}>{t("virtual_board.focus.deck_return.top")}</button>
                                        <button class="m-1 flex-fill" onClick={() => sendToDeck("bottom")}>{t("virtual_board.focus.deck_return.bottom")}</button>
                                        <button class="m-1 flex-fill" onClick={() => sendToDeck("shuffle")}>{t("virtual_board.focus.deck_return.shuffle")}</button>
                                    </div>
                                </div>
                            )}
                            {(cardFocused.source == "hero-expedition" || cardFocused.source == "companion-expedition" || cardFocused.source == "hero" || cardFocused.source == "landmarks" || (cardFocused.source == "reserve" && cardFocused.card.type == "CHARACTER")) && (
                                <div class="d-flex quick-tokens justify-content-center">
                                    <div class="d-flex flex-row">
                                        <QuickTokenButton token="boost" card={cardFocused} setCard={setCardFocused} />
                                        <QuickTokenButton token="divers" card={cardFocused} setCard={setCardFocused} />
                                        <div class="d-flex flex-column token-column justify-content-around align-items-center">
                                            <QuickTokenButton token="asleep" card={cardFocused} setCard={setCardFocused} />
                                            <QuickTokenButton token="anchored" card={cardFocused} setCard={setCardFocused} />
                                            <QuickTokenButton token="fleeting" card={cardFocused} setCard={setCardFocused} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div class="right-side shadowed-light">
                    <GlassBackground />
                    <div class="d-flex flex-column justify-content-between h-100">
                        <div class="navigation d-flex flex-row position-relative px-3 mb-1 mt-1">
                            <img class="app-icon" src={require('../Game/Icons/app-icon.png')} />
                            <h3 class="me-auto">Exalts Table</h3>
                            <LanguageSelect />
                        </div>
                        <div class="d-flex flex-column justify-content-start p-2 hide-scrollbar main-content">
                            {/*<Button onClick={() => call()}>Call Test</Button>*/}
                            {props.useManualMode && (<a class="new-turn-button" onClick={() => endTurn()}>{t("virtual_board.menu.new_turn")}</a>)}
                            {!props.isYourSection && (<div class="d-flex flex-column justify-content-start">
                                <div class="d-flex flex-column text-start shadowed-light content-section position-relative">
                                    <GlassBackground />
                                    <h3>{t("virtual_board.menu.how_to.content_play_title")}</h3>
                                    <p>{t("virtual_board.menu.how_to.content_play")}</p>
                                    <VirtualShortcutsMemo />
                                </div>
                                <div class="d-flex flex-column text-start shadowed-light content-section position-relative">
                                    <GlassBackground />
                                    <h3>{t("virtual_board.menu.time_travel.title")}</h3>
                                    <p>{t("virtual_board.menu.time_travel.content")}</p>
                                    {timeTravel.map((tt, i) => {
                                        return (<div class={"button my-1 d-flex flex-row align-items-center justify-content-center" + (i == currentTurn ? " primary" : " secondary")} onClick={() => travelThroughTime(i)}>
                                            <h3 class="m-0 ms-2">{t("virtual_board.menu.turn")} {i + 1}</h3>
                                            <p class="m-0 ms-auto">{t("virtual_board.board.card_played_indication.mana")} {tt.mana.length}</p>
                                            <p class="m-0 ms-2">{t("virtual_board.board.card_played_indication.hand")} {tt.hand.length}</p>
                                            <p class="m-0 ms-2 me-2">{t("virtual_board.board.card_played_indication.reserve")} {tt.reserve.length}</p>
                                        </div>)
                                    })}
                                </div>
                            </div>)}
                        </div>
                        {!props.isYourSection && (<div class="d-flex flex-column position-relative bottom-section">
                            <a class="new-turn-button" onClick={() => endTurn()} style={{ height: "8vh" }}>
                                <div class="d-flex flex-row justify-content-center align-items-center my-3">
                                    <h3 class="mx-0 mb-0">{t("virtual_board.menu.turn")} {currentTurn + 1}</h3>
                                    <p class="mb-0 mx-3">-</p>
                                    {t("virtual_board.menu.new_turn_with_key")}
                                </div></a>
                        </div>)}
                    </div>
                </div>
            </div>
            {(!props.boardData || isFullscreen) && (<QuickTokenInfo holdingShift={isHoldingShiftRef.current} quickToken={quickTokenModeRef.current} change={quickTokenModeChanged} />)}
            <DraggedCard cardDragged={cardDragged} />
            {showDiscard && (
                <DiscardPanel discard={discard} isOpponent={props.isOpponent} hide={() => setShowDiscard(false)} play={
                    (card, i) => {
                        setNewCardDragged({ source: "discard", card: card, index: i })
                        setShowDiscard(false)
                    }
                } toHand={
                    (card, i) => {
                        let handTmp = [...hand]
                        handTmp.push(card)
                        setHand(handTmp)
                        setDiscard([...discard].toSpliced(i, 1))
                    }
                } />)}
            {showManaPanel && (<ShowManaPanel mana={mana} isOpponent={props.isOpponent} hide={() => { setShowManaPanel(false); setShowManaCards(false) }} play={
                (card, i) => {
                    setNewCardDragged({ source: "mana", card: card, index: i })
                    setShowManaPanel(false)
                    setShowManaCards(false)
                }
            } toHand={
                (card, i) => {
                    let handTmp = [...hand]
                    handTmp.push(card)
                    setHand(handTmp)
                    setMana([...mana].toSpliced(i, 1))
                }
            } />)}
            {showReset && (<FullScreenPanel title={t("virtual_board.menu.button_restart.title")} hidePanel={() => setShowReset(false)} component={
                <div class="d-flex flex-column">
                    <p>{t("virtual_board.menu.button_restart.content")}</p>
                    <div class="d-flex flex-row justify-content-around">
                        <button onClick={() => setShowReset(false)} class="wide">{t("commons.no")}</button>
                        <button onClick={() => { reset(); setShowReset(false) }} class="wide">{t("commons.yes")}</button>
                    </div>
                </div>
            } />)}
            {showCustomization && (<FullScreenPanel title={t("virtual_board.menu.button_options.title")} hidePanel={() => setShowCustomization(false)} component={
                <div class="d-flex flex-column">
                    <h3>{t("virtual_board.menu.button_options.content_background_title")}</h3>
                    <p>{t("virtual_board.menu.button_options.content_background")}</p>
                    <div class="d-flex flex-row">
                        <BackgroundImagePicker value={1} clicked={() => setBackgroundChoice(1)} />
                        <BackgroundImagePicker value={2} clicked={() => setBackgroundChoice(2)} />
                        <BackgroundImagePicker value={3} clicked={() => setBackgroundChoice(3)} />
                    </div>
                    <div class="d-flex flex-row">
                        <BackgroundImagePicker value={4} clicked={() => setBackgroundChoice(4)} />
                        <BackgroundImagePicker value={5} clicked={() => setBackgroundChoice(5)} />
                        <BackgroundImagePicker value={6} clicked={() => setBackgroundChoice(6)} />
                    </div>
                    <div class="d-flex flex-row">
                        <BackgroundImagePicker value={7} clicked={() => setBackgroundChoice(7)} />
                        <BackgroundImagePicker value={8} clicked={() => setBackgroundChoice(8)} />
                        <BackgroundImagePicker value={9} clicked={() => setBackgroundChoice(9)} />
                    </div>
                    <div class="d-flex flex-row">
                        <BackgroundImagePicker value={10} clicked={() => setBackgroundChoice(10)} />
                    </div>
                    {/*<h3>Use mobile mode</h3>
                    <div class="d-flex flex-row justify-content-between">
                        <p>Improve virtual experience for mobile users.</p>
                        <Button onClick={() => setUseMobileMode(!useMobileMode)}>{useMobileMode ? "Disable" : "Enable"}</Button>
            </div>*/}
                    {/*
                                        <h3>Streamer layout</h3>
                                        <div class="d-flex flex-row justify-content-between">
                        <p>Change the layout to make it easier for streamers to show a game.</p>
                        <button onClick={() => setUseStreamerLayout(!useStreamerLayout)}>{useStreamerLayout ? "Disable" : "Enable"}</button>
                    </div>
                    */}
                </div>
            } />)}
            {showEndSession && (<FullScreenPanel title={t("virtual_board.menu.button_leave.title")} component={
                <div class="d-flex flex-column">
                    <p>{t("virtual_board.menu.button_leave.content")}</p>
                    <div class="d-flex flex-row justify-content-around">
                        <button onClick={() => setShowEndSession(false)} class="wide">{t("commons.no")}</button>
                        <button onClick={() => { props.closeDeckTester(); setShowEndSession(false) }} class="wide">{t("commons.yes")}</button>
                    </div>
                </div>
            } hidePanel={() => setShowEndSession(false)} />)}
            {(props.isSpectator || props.isOpponent) && (<CardPlayedAnim card={lastPlayedCard} cardsPositions={cardsPositions.current} virtualBoardId={virtualBoardId} />)}
        </div>
    )

    function DeckExtra(props) {
        return (
            <div class="extra">
                <div class="extra-toggler">
                    <p class="m-0">+</p>
                </div>
                <div class="extra-content d-flex flex-column">
                    <button className="mb-1 mt-0 mx-0" onClick={() => extraDeckActions("top-to-mana")}>{t("virtual_board.board.deck.top_to_mana")}</button>
                    <button className="mb-1 mt-0 mx-0" onClick={() => extraDeckActions("reserve-exhausted")}>{t("virtual_board.board.deck.reserve_exhausted")}</button>
                    <p class="mt-2">{t("virtual_board.board.deck.ask_discord")}</p>
                </div>
            </div>
        )
    }

    function LeftSideButton(props) {
        return (<Button class={"left-side-button" + (props.selected ? " selected" : "")} onClick={props.clicked}>
            <img src={require('../Game/Icons/' + props.icon + '.png')} />
            {props.description && (<p class="shadowed">{props.description}</p>)}
        </Button>)
    }

    function BackgroundImagePicker(props) {
        return (<div class={"background-image-picker background-choice-" + props.value}>
            <div class="w-100 h-100" onClick={() => { props.clicked() }}></div>
        </div>)
    }

    function DiscardPanel(props) {
        const scrollRef = useHorizontalScroll();

        return (
            <div class="discard-panel fullscreen-panel">
                <img src={require('../Game/Icons/x.png')} class="close-button" onClick={() => props.hide()} />
                <div class="d-flex flex-column">
                    <h2 class="mb-5">{t("virtual_board.board.discard.title")}</h2>
                    <div class="d-flex flex-row" ref={scrollRef}>
                        {props.discard.map((card, i) => {
                            return (<div class="d-flex flex-column">
                                <Card card={card} />
                                {!props.isOpponent && (<div class="d-flex flex-column"><Button class="mt-2" onClick={() => {
                                    props.play(card, i)
                                }}>{t("virtual_board.board.discard.play")}</Button>
                                    <Button class="mt-2" onClick={() => {
                                        props.toHand(card, i)
                                    }}>{t("virtual_board.board.discard.to_hand")}</Button></div>)}
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        )
    }

    function ShowManaPanel(props) {
        const scrollRef = useHorizontalScroll();

        return (
            <div class="show-mana-panel d-none pe-none discard-panel fullscreen-panel">
                <img src={require('../Game/Icons/x.png')} class="close-button" onClick={() => props.hide()} />
                <div class="d-flex flex-column w-100">
                    <h2 class="mb-5">Mana</h2>
                    <div class="d-flex flex-row" ref={scrollRef}>
                        {props.mana.map((card, i) => {
                            return (<div class="d-flex flex-column">
                                <Card card={card} />
                                {!props.isOpponent && (<div class="d-flex flex-column"><Button class="mt-2" onClick={() => {
                                    props.play(card, i)
                                }}>{t("virtual_board.board.discard.play")}</Button>
                                    <Button class="mt-2" onClick={() => {
                                        props.toHand(card, i)
                                    }}>{t("virtual_board.board.discard.to_hand")}</Button></div>)}
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        )
    }

    function QuickTokenButton(props) {
        const add = () => {
            let cardTmp = { ...props.card }
            if (cardTmp.card.tokens == undefined) {
                cardTmp.card = { ...cardTmp.card, tokens: {} }
            }
            if (props.token == "boost") {
                cardTmp.card.tokens = { ...cardTmp.card.tokens, boost: cardTmp.card.tokens.boost == undefined ? 1 : cardTmp.card.tokens.boost + 1 }
            } else if (props.token == "divers") {
                cardTmp.card.tokens = { ...cardTmp.card.tokens, divers: cardTmp.card.tokens.divers == undefined ? 1 : cardTmp.card.tokens.divers + 1 }
            }
            props.setCard(cardTmp)
        }

        const remove = () => {
            let cardTmp = { ...props.card }
            if (cardTmp.card.tokens == undefined) {
                return
            }
            if (props.token == "boost") {
                if (cardTmp.card.tokens.boost == undefined) {
                    return
                }
                let value = cardTmp.card.tokens.boost - 1
                if (value <= 0) {
                    delete cardTmp.card.tokens.boost
                } else {
                    cardTmp.card.tokens.boost = value
                }
            } else if (props.token == "divers") {
                if (cardTmp.card.tokens.divers == undefined) {
                    return
                }
                let value = cardTmp.card.tokens.divers - 1
                if (value <= 0) {
                    delete cardTmp.card.tokens.divers
                } else {
                    cardTmp.card.tokens.divers = value
                }
            }
            props.setCard(cardTmp)
        }

        if (props.token == "boost" || props.token == "divers") {
            return (
                <div class={"d-flex flex-column quick-token-button justify-content-between " + props.token}>
                    <button onClick={() => add()}>+</button>
                    <img class="m-2" src={require('./Assets/' + props.token + '.png')} />
                    <button onClick={() => remove()}>-</button>
                </div>
            )
        }

        const toggle = () => {
            let cardTmp = { ...props.card }
            if (cardTmp.card.tokens == undefined) {
                cardTmp.card = { ...cardTmp.card, tokens: {} }
            }
            if (props.token == "anchored") {
                cardTmp.card.tokens = { ...cardTmp.card.tokens, anchored: cardTmp.card.tokens.anchored == undefined ? 1 : undefined }
            } else if (props.token == "asleep") {
                cardTmp.card.tokens = { ...cardTmp.card.tokens, asleep: cardTmp.card.tokens.asleep == undefined ? 1 : undefined }
            } else if (props.token == "fleeting") {
                cardTmp.card.tokens = { ...cardTmp.card.tokens, fleeting: cardTmp.card.tokens.fleeting == undefined ? 1 : undefined }
            }
            props.setCard(cardTmp)
        }

        const isSelected = props.card.card.tokens && ((props.token == "anchored" && props.card.card.tokens.anchored) || (props.token == "asleep" && props.card.card.tokens.asleep) || (props.token == "fleeting" && props.card.card.tokens.fleeting))

        return (
            <div class={("d-flex flex-column quick-token-button") + (isSelected ? " selected" : " ")} onClick={() => toggle()}>
                <img class="" src={require('./Assets/' + props.token + '.png')} />
            </div>
        )
    }
}

function DeckVisual(props) {
    const [triggerAnim, setTriggerAnim] = useState(false)
    const [previousLength, setPreviousLength] = useState(9000)
    const [isIni, setIsIni] = useState(true)
    useEffect(() => {
        let newL = props.deckLength
        if (newL < previousLength) {
            setPreviousLength(newL)
            setTriggerAnim(!triggerAnim)
            if (previousLength != 9000 && newL > 0) {
                setIsIni(false)
            }
        } else {
            setPreviousLength(newL)
        }
    }, [props.deckLength])
    return (
        <div class="deck-visual">
            {props.deckLength > 1 && (<div class="deck-background shadowed"></div>)}
            <div class="deck-top" style={{ top: "-" + props.deckLength / 15 + "vh" }}>
                {props.deckLength > 1 && (<img src={require('./Assets/card-back.png')} />)}
                {props.deckLength > 0 && (<img class="animated-part" key={triggerAnim} src={require('./Assets/card-back.png')} />)}
                {!isIni && (<img class="disappearing-part" key={triggerAnim + "2"} src={require('./Assets/card-back.png')} />)}
                <div class="pile-count undraggable"><p>{props.deckLength}</p></div>
                {props.children}
            </div>
        </div>
    )
}

function BoardSection(props) {
    const scrollRef = useHorizontalScroll();

    return (
        <div ref={scrollRef} class={"board-section side-scroll " + props.sectionTitle + (props.selectable ? " selectable" : "") + (props.single ? " single" : "")} onPointerUp={() => props.sectionClicked(props.sectionTitle)}>
            <TransitionGroup component={"div"} >
                {props.component}
            </TransitionGroup>
        </div>
    )
}

export function DraggedCard(props) {
    const { t, i18n } = useLanguageContext()
    const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 })

    useEffect(() => {
        const handleWindowMouseMove = e => {
            setCursorPosition({ top: e.clientY, left: e.clientX });
        };
        window.addEventListener('pointermove', handleWindowMouseMove);

        return () => {
            window.removeEventListener(
                'pointermove',
                handleWindowMouseMove,
            );
        };
    }, []);

    let card = props.cardDragged ? props.cardDragged.card : false
    if (card) {
        return (<div style={{ position: 'fixed', ...cursorPosition }} class="zoomed-card focused-card">
            <ImageMemo src={card.images ? card.images[i18n.language] : card.image} className="shadowed" />
            <div class="position-absolute top-0 start-0 w-100 mana-info"><h2>{t("virtual_board.board.card_played_indication.mana")}</h2></div>
            <div class="position-absolute top-0 start-0 w-100 hero-expedition-info"><h2>{t("virtual_board.board.card_played_indication.hero")}</h2></div>
            <div class="position-absolute top-0 start-0 w-100 companion-expedition-info"><h2>{t("virtual_board.board.card_played_indication.companion")}</h2></div>
            <div class="position-absolute top-0 start-0 w-100 reserve-info"><h2>{t("virtual_board.board.card_played_indication.reserve")}</h2></div>
            <div class="position-absolute top-0 start-0 w-100 landmarks-info"><h2>{t("virtual_board.board.card_played_indication.landmarks")}</h2></div>
            <div class="position-absolute top-0 start-0 w-100 discard-info"><h2>{t("virtual_board.board.card_played_indication.discard")}</h2></div>
        </div>)
    }
}

function Card(props) {
    const { i18n } = useLanguageContext()
    const onEnter = () => {
        if (props.zoomVirtualCard) {
            props.zoomVirtualCard(props.card)
        }
    }

    const onLeave = () => {
        if (props.zoomVirtualCard) {
            props.zoomVirtualCard(false)
        }
    }

    const onMouseUp = () => {
        if (props.onClick) {
            const isDraggingAllow = props.onClick()
            if (isDraggingAllow && props.focusCard && props.card.type != "HERO") {
                setIsDragging(true)
            }
        }
    }

    const [isDragging, setIsDragging] = useState(false)

    useEffect(() => {
        if (props.cardDragged === false) {
            setIsDragging(false)
        }
    }, [props.cardDragged])

    return (
        <CSSTransition key={props.card.cardId} timeout={700} classNames="item">
            <div class={("tapped-wrapper") + (props.missingMana ? " missing-mana" : "") + (isDragging ? " is-dragging" : "") + (props.card.tapped ? " tapped" : "") + " " + (props.className ? props.className : "") + (props.card.rarity ? " " + props.card.rarity : "") + (props.card.type ? " " + props.card.type  : "")}
                onPointerEnter={() => onEnter()}
                onPointerLeave={() => onLeave()}>
                <div class="board-card" id={props.card.cardId ? "card-id-" + props.card.cardId + "-" + props.virtualBoardId : "card-id-none"}>
                    <ImageMemo src={props.card.images ? props.card.images[i18n.language] : props.card.image} className="card-image" />
                    {props.card.tokens && (
                        <div class="d-flex flex-row tokens justify-content-center">
                            {props.card.tokens.boost && (
                                <div class="d-flex flex-column boost-token">
                                    <img src={require('./Assets/boost.png')} />
                                    <p class="m-0">{props.card.tokens.boost}</p>
                                </div>
                            )}
                            {props.card.tokens.divers && (
                                <div class="d-flex flex-column boost-token">
                                    <img src={require('./Assets/divers.png')} />
                                    <p class="m-0">{props.card.tokens.divers}</p>
                                </div>
                            )}
                            <div class="d-flex flex-column">
                                {props.card.tokens.anchored && (
                                    <div class="d-flex flex-column">
                                        <img src={require('./Assets/anchored.png')} />
                                    </div>
                                )}
                                {props.card.tokens.asleep && (
                                    <div class="d-flex flex-column">
                                        <img src={require('./Assets/asleep.png')} />
                                    </div>
                                )}
                                {props.card.tokens.fleeting && (
                                    <div class="d-flex flex-column">
                                        <img src={require('./Assets/fleeting.png')} />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <div class="drag-wrapper" onPointerDown={() => {
                        onMouseUp()
                    }}
                        onPointerUp={() => setIsDragging(false)}></div>
                    {props.card.type != "HERO" && props.card.manaCost && (
                        <div class="card-power-and-cost d-flex flex-column">
                            <div class="costs">
                                <div><p>{props.card.manaCost[0]}</p></div>
                                <div><p>{props.card.manaCost[1]}</p></div>
                            </div>
                            {(props.card.type == "CHARACTER" || props.card.type == "TOKEN") && (<div class="d-flex flex-column">
                                {props.card.power.map((v) => {
                                    let p = parseInt(v)
                                    let b = 0
                                    if (props.card.tokens && props.card.tokens.boost) {
                                        b = props.card.tokens.boost
                                    }
                                    return (<div class={"pow" + (b == 0 ? "" : " boosted") + (p + b == 0 ? " no-value" : "")}><p>{p + b}</p></div>)
                                })}
                            </div>)}
                        </div>
                    )}

                    {props.focusCard && (
                        <div class="focus-card-buttons d-flex flex-row justify-content-end">
                            <div class="focus-card-button support undraggable" onClick={() => { props.quickActionButton() }}>
                                <img src={require('../Game/Icons/support.png')} />
                            </div>
                            <div class="focus-card-button exhaust undraggable" onClick={() => { props.quickActionButton() }}>
                                <img src={require('../Game/Icons/exhaust.png')} />
                            </div>
                            <div class="focus-card-button undraggable" onClick={() => { props.focusCard(); setIsDragging(false) }}>
                                <p class="m-0">...</p>
                            </div>
                        </div>)}

                </div>
            </div>
        </CSSTransition>)
}

function QuickTokenInfo(props) {
    const [message, setMessage] = useState("")
    const { t } = useLanguageContext()

    useEffect(() => {
        if (props.quickToken) {
            let newMessage = ""
            if (props.holdingShift) {
                newMessage += t("virtual_board.board.quick_tokens.remove")
            } else {
                newMessage += t("virtual_board.board.quick_tokens.add")
            }
            if (props.quickToken == "boost") {
                newMessage += " " + t("virtual_board.board.quick_tokens.boost")
            } else if (props.quickToken == "divers") {
                newMessage += " " + t("virtual_board.board.quick_tokens.divers")
            }

            if (props.quickToken == "anchored") {
                newMessage = t("virtual_board.board.quick_tokens.anchored")
            } else if (props.quickToken == "fleeting") {
                newMessage = t("virtual_board.board.quick_tokens.fleeting")
            } else if (props.quickToken == "asleep") {
                newMessage = t("virtual_board.board.quick_tokens.asleep")
            } else if (props.quickToken == "exhaust") {
                newMessage = t("virtual_board.board.quick_tokens.exhaust")
            } else if (props.quickToken == "clear") {
                newMessage = t("virtual_board.board.quick_tokens.clear")
            }
            setMessage(newMessage)
        } else {
            setMessage(false)
        }
    }, [props.change])

    return (<div class={"quick-token-selection-info shadowed" + (message ? "" : " hide")}>
        <div class="blurred-background"></div>
        {message && (<p class="m-0">{message}</p>)}
    </div>)
}

function CardPlayedAnim(props) {
    const { t, i18n } = useLanguageContext()
    const [card, setCard] = useState(props.card)
    const [pos, setPos] = useState({ x: 0, y: 0 })
    const [isAtIniPos, setIsAtIniPos] = useState(true)

    useEffect(() => {
        setCard(props.card)
        if (props.card) {
            let e = document.getElementById("card-id-" + props.card.cardId + "-" + props.virtualBoardId)
            if (e) {
                let destinationRect = e.getBoundingClientRect()

                /*if (props.card.sourceRect) {
                    setPos({ x: props.card.sourceRect.x, y: props.card.sourceRect.y })
                } else {
                    setPos({ x: 0, y: 0 })
                }*/
                if (props.card.source) {
                    let sourceRect = props.cardsPositions[props.card.source.cardId]
                    if (sourceRect) {
                        setPos({ x: sourceRect.left, y: sourceRect.top })
                    }
                } else {
                    setPos({ x: 0, y: 0 })
                }
                setIsAtIniPos(true)
                setTimeout(() => {
                    setIsAtIniPos(false)
                    setPos({ x: destinationRect.left, y: destinationRect.top })
                }, 100)
            }
        }
    }, [props.card])

    if (card && !(pos.x == 0 && pos.y == 0)) {
        return (<div key={card.cardId + "-anim-" + card.sourceSection} class={"position-fixed pe-none card-played-anim shadowed " + (isAtIniPos ? " ini-pos" : "") + (card.sourceSection == "hand" ? " hidden" : "")}
            style={{ left: pos.x, top: pos.y }}
        >
            <ImageMemo src={card.images ? card.images[i18n.language] : props.card.image} className="card-image w-100 h-100" />
        </div>)
    }
}