import "./Game.css"
import React, { useState, useRef, useEffect, useCallback, useLayoutEffect } from "react"
import { TumulteMemo } from "./Tumulte/TumulteSection";
import ReactPlayer from "react-player";
import FullScreenPanel from "../Commons/FullScreenPanel";
import { arraysEqual, decodeQRCodeFromImageJSQR, pushToDict, shuffleArray, uuidv4 } from "../QRCodeRecognition/Functions";
import CardRecognitionColumn from "../QRCodeRecognition/CardRecognitionColumn";
import LeftBar from "./Components/LeftBar";
import { Counters, PowerCounterMemo } from "./Components/PowerCounter";
import { PowerCounterMemo2v2 } from "./Components/PowerCounter2v2";
import { ImageMemo, VirtualBoardGameViewMemo } from "./Components/Memos";
import PopUpPanel from "./Components/PopUpPanel";
import DarkMode from "../Commons/DarkMode/DarkMode";
import PlayerGameSection, { ButtonWithConfirmation, DiceRolls, PlayerGameSectionMemo } from "./Components/PlayerGameSection/PlayerGameSection";
import { powerCountersActiveDefault, powerCountersDefault, tumulteCardsData } from "./Components/GameDefaults";
import Button from "../Commons/Buttons";
import TumulteSection2v2 from "./Tumulte/TumulteSection2v2";
import { useLanguageContext } from "../Commons/Translations/languageContext";
import LanguageSelect from "../Commons/Translations/LanguageSelect";
import Chat from "./Components/Chat/Chat";
import './DigitalOnly.css';
import UserName from "../Commons/UserName";
import PopUpMessage from "../Commons/PopUpMessage";
import './Components/PlayerGameSection/2v2.css'
import VirtualShortcutsMemo from "../Commons/Misc/VirtualShortcutsMemo";

export default function GameReborn(props) {
    // Debug options

    const showDebugInfo = false
    const is2v2 = props.gameDataGlobal.gameModeInfo.players == 4
    const { t } = useLanguageContext()


    // Shared Data

    const [gameDataGlobal, setGameDataGlobal] = useState(props.gameDataGlobal)
    const gameDataGlobalRef = useRef(false)
    const [gameDataPlayers, setGameDataPlayers] = useState(props.gameDataPlayers)
    const playerId = props.playerId

    // Game Data

    const initialized = useRef(false)
    const playersCount = props.gameDataGlobal.turnOrder.length
    const worker = useRef(null);
    const [QRCodeScanningSpeed, setQRCodeScanningSpeed] = useState(1)
    const QRCodeScanningSpeedRef = useRef(0)
    const [latestScannedQRCode, setLatestScannedQRCode] = useState(false)
    const [playerRefIndexes, setPlayerRefIndexes] = useState({})

    // UI States

    const [showOptions, setShowOptions] = useState(false)
    const [showRestart, setShowRestart] = useState(false)
    const [showEndSession, setShowEndSession] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const [showDiceThrowPanel, setShowDiceThrowPanel] = useState(false)

    const [focusedPlayerGameSection, setFocusedPlayerGameSection] = useState(false)
    const [hideRightSide, setHideRightSide] = useState(false)
    const [hideRightSideVirtual, setHideRightSideVirtual] = useState(false)
    const [followActivePlayer, setFollowActivePlayer] = useState(false)
    //const [useStreamerLayout, setUseStreamerLayout] = useState(false)
    const [useManualMode, setUseManualMode] = useState(false)

    const [QRCodeFound, setQRCodeFound] = useState(false)   // To show feedback card scanned successfully
    const [isTumulteMinimized, setIsTumulteMinimized] = useState(true)
    const [rightSideSelection, setRightSideSelection] = useState(1)

    // I Don't know

    const [resetVirtualDeck, setResetVirtualDeck] = useState(0)
    const [newTurnVirtualDeck, setNewTurnVirtualDeck] = useState(0)
    const [changeBackgroundVirtualDeck, setChangeBackgroundVirtualDeck] = useState(0)

    const [isSpectator, setIsSpectator] = useState(props.gameDataGlobal.spectatorId == playerId)
    const [virtualPower, setVirtualPower] = useState(JSON.parse(JSON.stringify(powerCountersDefault)))




    // ------------------------ UseEffects ------------------------

    useEffect(() => {
        let tmp = { ...gameDataPlayers[playerId] }
        tmp.tumultePositions = [0, 7]
        tmp.diceRolls = []
        tmp.powerCounters = JSON.parse(JSON.stringify({ ...powerCountersDefault }))
        tmp.powerCountersActive = JSON.parse(JSON.stringify(powerCountersActiveDefault))
        setPlayerGameData(tmp)
        if (!useManualMode && gameDataPlayers[playerId].gameMode == "virtual") {
            triggerVirtualDeckReset()
        }
        setZoomedVirtualCard(false)
    }, [gameDataGlobal.gameCount])

    useEffect(() => {
        if (followActivePlayer) {
            setFocusedPlayerGameSection(gameDataGlobal.activePlayerIndex)
        }
    }, [followActivePlayer])

    useEffect(() => {
        let tmp = { ...gameDataPlayers[playerId] }

        if (!tmp.boardData) {
            tmp.powerCounters = JSON.parse(JSON.stringify({ ...powerCountersDefault }))
        } else {
            tmp.powerCounters = newPowerCountersValue(tmp.boardData, JSON.parse(JSON.stringify({ ...powerCountersDefault })), true)
        }
        setPlayerGameData(tmp)
        if (gameDataGlobal.turnCounter > 1) {
            triggerVirtualNewTurn()
        }
        setZoomedVirtualCard(false)
    }, [gameDataGlobal.turnCounter])

    useLayoutEffect(() => {
        if (followActivePlayer) {
            setFocusedPlayerGameSection(gameDataGlobal.activePlayerIndex)
        }
    }, [gameDataGlobal.activePlayerIndex])

    useLayoutEffect(() => {
        setGameDataPlayers(props.gameDataPlayers)
    }, [props.gameDataPlayers])

    useLayoutEffect(() => {
        // Check for digital only
        if (props.gameDataGlobal.gameModeInfo.modeId == "_1v1_standard") {
            if (props.gameDataPlayers[props.playersRef[0].id].gameMode == "virtual" && props.gameDataPlayers[props.playersRef[1].id].gameMode == "virtual") {
                setAllowDigitalOnly(true)
            } else {
                setUseDigitalOnly(false)
                setAllowDigitalOnly(false)
            }
        } else if (props.gameDataGlobal.gameModeInfo.modeId == "_1v1_standard_observer") {
            if (props.gameDataPlayers[props.gameDataGlobal.turnOrder[0]].gameMode == "virtual" && props.gameDataPlayers[props.gameDataGlobal.turnOrder[1]].gameMode == "virtual") {
                setAllowDigitalOnly(true)
            } else {
                setUseDigitalOnly(false)
                setAllowDigitalOnly(false)
            }
        } else {
            setUseDigitalOnly(false)
            setAllowDigitalOnly(false)
        }
    }, [props.gameDataPlayers[gameDataGlobal.turnOrder[0]].gameMode, props.gameDataPlayers[gameDataGlobal.turnOrder[1]].gameMode])
    /*
        useLayoutEffect(() => {
            if (props.gameDataGlobal.gameModeInfo.modeId == "_1v1_standard") {
                if (props.gameDataPlayers[props.playersRef[0].id].gameMode == "virtual" && props.gameDataPlayers[props.playersRef[1].id].gameMode == "virtual") {
                    setAllowDigitalOnly(true)
                } else {
                    setAllowDigitalOnly(false)
                }
            } else if (props.gameDataGlobal.gameModeInfo.modeId == "_1v1_standard_observer") {
                if (props.gameDataPlayers[props.playersRef[1].id].gameMode == "virtual" && props.gameDataPlayers[props.playersRef[2].id].gameMode == "virtual") {
                    setAllowDigitalOnly(true)
                } else {
                    setAllowDigitalOnly(false)
                }
            } else {
                setAllowDigitalOnly(false)
            }
        }, [props.gameDataPlayers])
    */
    useLayoutEffect(() => {
        setGameDataGlobal(props.gameDataGlobal)
        gameDataGlobalRef.current = { ...props.gameDataGlobal }
    }, [props.gameDataGlobal])

    useLayoutEffect(() => {
        if (gameDataPlayers[playerId].gameMode == "webcam") {
            QRCodeScanningSpeedRef.current = QRCodeScanningSpeed
        } else {
            QRCodeScanningSpeedRef.current = 0
        }
    }, [gameDataPlayers[playerId].gameMode])
    /*
        useEffect(() => {
            let code = props.gameDataGlobal.latestScannedQRCode
            if (code && code.sender !== playerId && code.data !== latestScannedQRCode) {
                setLatestScannedQRCode("" + code.data)
                let tmpData = { ...gameDataGlobal }
                tmpData.latestScannedQRCode = false
                setGlobalGameData(tmpData)
            }
        }, [props.gameDataGlobal.latestScannedQRCode])
    */
    useEffect(() => {
        // Create a new web worker
        const myWorker = new Worker(new URL('./worker.js', import.meta.url));

        // Set up event listener for messages from the worker
        myWorker.onmessage = function (event) {
            if (event.data != false && event.data.length > 0) {
                let code = event.data[0]
                if (latestScannedQRCode != code) {
                    let tmpData = { ...gameDataGlobalRef.current }
                    tmpData.latestScannedQRCode = { sender: playerId, data: code }
                    setGlobalGameData(tmpData)

                    setQRCodeFound(true)

                    setTimeout(() => {
                        setQRCodeFound(false)
                    }, 500)
                } else {
                    console.log("same qrcode, not updating")
                }
            }

            let timer = QRCodeScanningSpeedRef.current == 0 ? 1000 : QRCodeScanningSpeedRef.current * 1000
            setTimeout(() => {
                checkForQRCodesPeriodically()
            }, timer)
        };

        // Save the worker instance to state
        worker.current = myWorker

        if (props.isHost) {
            iniGame()
        }

        if (!initialized.current) {
            let tmpIndexes = {}
            props.playersRef.forEach((ref, i) => {
                pushToDict(tmpIndexes, ref.id, i)
            })
            tmpIndexes.tumulteSection = []
            if (isSpectator) {
                props.gameDataGlobal.turnOrder.forEach((id) => {
                    tmpIndexes.tumulteSection.push(id)
                })
            } else {
                props.gameDataGlobal.turnOrder.forEach((id) => {
                    if (id == playerId) {
                        tmpIndexes.tumulteSection.unshift(id)
                    } else {
                        tmpIndexes.tumulteSection.push(id)
                    }
                })
            }
            setPlayerRefIndexes({ ...tmpIndexes })
            initialized.current = true
            setTimeout(() => {
                // TEMPORARY REMOVED
                //checkForQRCodesPeriodically()
            }, 2000)
        }

        return () => {
            myWorker.terminate();
        }
    }, []);

    // ------------------------ Functions ------------------------

    const iniGame = () => {
        console.log("INI")
        let gameDatalocalTmp = { ...gameDataGlobal }
        gameDatalocalTmp.gameCount = gameDatalocalTmp.gameCount + 1
        gameDatalocalTmp.lastPlayedCard = false

        let newTumulte = getNew2PlayersTumulte()
        if (is2v2) {
            newTumulte = getNew4PlayersTumulte()
        }
        gameDatalocalTmp.tumulteCards = newTumulte
        gameDatalocalTmp.turnCounter = 1
        const firsPlayer = Math.floor(Math.random() * playersCount)
        gameDatalocalTmp.firstPlayerIndex = firsPlayer
        gameDatalocalTmp.activePlayerIndex = firsPlayer
        setGlobalGameData(gameDatalocalTmp)
        let tmpPlayerData = { ...gameDataPlayers[playerId] }
        tmpPlayerData.tumultePositions = [0, 7]
        tmpPlayerData.diceRolls = []
        setPlayerGameData(tmpPlayerData)
    }

    const getNew2PlayersTumulte = () => {
        const side1 = Math.floor(Math.random() * 2) + 1;
        const side2 = Math.floor(Math.random() * 2) + 1;
        const side3 = Math.floor(Math.random() * 2) + 1;
        const availableTumulteCards = ["tumulte-1-" + side1, "tumulte-2-" + side2, "tumulte-3-" + side3]
        shuffleArray(availableTumulteCards)

        return [{
            data: tumulteCardsData[availableTumulteCards[0]],
            hasBeenRevealed: false
        }, {
            data: tumulteCardsData[availableTumulteCards[1]],
            hasBeenRevealed: false
        }, {
            data: tumulteCardsData[availableTumulteCards[2]],
            hasBeenRevealed: false
        }]
    }

    const getNew4PlayersTumulte = () => {
        let availableTumulteCards = []
        for (let i = 0; i < 2; i++) {
            for (let j = 1; j <= 3; j++) {
                const side = Math.floor(Math.random() * 2) + 1;
                availableTumulteCards.push("tumulte-" + j + "-" + side)
            }
        }

        let availableCenterTumulteCards = []
        for (let j = 1; j <= 3; j++) {
            const side = Math.floor(Math.random() * 2) + 1;
            availableCenterTumulteCards.push("2v2-tumulte-" + j + "-" + side)
        }

        shuffleArray(availableTumulteCards)
        shuffleArray(availableCenterTumulteCards)

        return [{
            data: tumulteCardsData[availableCenterTumulteCards[0]],
            hasBeenRevealed: false
        }, {
            data: tumulteCardsData[availableTumulteCards[0]],
            hasBeenRevealed: false
        }, {
            data: tumulteCardsData[availableTumulteCards[1]],
            hasBeenRevealed: false
        }, {
            data: tumulteCardsData[availableTumulteCards[2]],
            hasBeenRevealed: false
        }, {
            data: tumulteCardsData[availableTumulteCards[3]],
            hasBeenRevealed: false
        }]
    }

    const changeFirstPlayer = () => {
        let gameDatalocalTmp = { ...gameDataGlobal }
        gameDatalocalTmp.turnCounter += 1
        gameDatalocalTmp.firstPlayerIndex = (gameDatalocalTmp.firstPlayerIndex + 1) % playersCount
        gameDatalocalTmp.activePlayerIndex = gameDatalocalTmp.firstPlayerIndex
        setGlobalGameData(gameDatalocalTmp)
    }

    async function checkForQRCodesPeriodically() {
        if (QRCodeScanningSpeedRef.current > 0) {
            let stream = props.playersRef[0].stream
            // Inchangé, à sortir
            const getBlobFromMediaStream = (stream) => {
                const video = document.createElement('video');
                const canvas = document.createElement('canvas')
                video.srcObject = stream;

                return new Promise((resolve, reject) => {
                    video.addEventListener('loadeddata', async () => {
                        const { videoWidth, videoHeight } = video;

                        canvas.width = videoWidth;
                        canvas.height = videoHeight;

                        try {
                            await video.play();
                            //context.imageSmoothingQuality = "high"
                            const context = canvas.getContext('2d', { willReadFrequently: false });

                            context.drawImage(video, 0, 0, videoWidth, videoHeight);
                            const imageData = context.getImageData(0, 0, videoWidth, videoHeight);
                            if (worker.current) {
                                worker.current.postMessage({ imageData: imageData })
                            }
                            video.remove()
                            video.srcObject = null
                            canvas.remove()
                            canvas.srcObject = null
                            resolve("")
                        } catch (error) {
                            video.remove()
                            video.srcObject = null
                            canvas.remove()
                            canvas.srcObject = null
                            reject(error);
                        }
                    });
                });
            }

            getBlobFromMediaStream(stream).then(blob => {
            }).catch(error => {
                let timer = QRCodeScanningSpeedRef.current == 0 ? 1000 : QRCodeScanningSpeedRef.current * 1000
                console.log(`Photo could not be taken. ${error}`);
                setTimeout(() => {
                    checkForQRCodesPeriodically()
                }, timer)
            })
        } else {
            setTimeout(() => {
                checkForQRCodesPeriodically()
            }, 1000)
        }
    }

    const clickVirtualCard = (card, share) => {
        return
        let tmp = "" + card.reference

        if (tmp !== latestScannedQRCode) {
            if (share) {
                let tmpData = { ...gameDataGlobal }
                tmpData.latestScannedQRCode = { sender: playerId, data: tmp }
                setGlobalGameData(tmpData)
            } else {
                setLatestScannedQRCode(tmp)
            }
        } else {
            console.log("same card, not updating")
        }
    }

    const [zoomedVirtualCard, setZoomedVirtualCard] = useState(false)

    const zoomVirtualCard = (card) => {
        setZoomedVirtualCard(card)
    }

    const throwDice = (sides) => {
        let tmp = { ...gameDataPlayers[playerId] }
        tmp.diceRolls.unshift({ id: uuidv4(), result: Math.floor(Math.random() * sides + 1), sides: sides })
        setPlayerGameData(tmp)
    }

    const changePlayerTumultePositions = (side, newValue) => {
        if (newValue < 0 || newValue > 7) { return }
        let tmp = { ...gameDataPlayers[playerId] }
        tmp.tumultePositions[side == "hero" ? 0 : 1] = newValue
        let newActive = {}

        let tmpTumulte = { ...gameDataGlobal }
        if (newValue == 1 || newValue == 2) {
            tmpTumulte.tumulteCards[0].hasBeenRevealed = true
            setGlobalGameData(tmpTumulte)
            newActive = tmpTumulte.tumulteCards[0].data.activeBiomes[(newValue + 1) % 2]
        } else if (newValue == 3 || newValue == 4) {
            tmpTumulte.tumulteCards[1].hasBeenRevealed = true
            setGlobalGameData(tmpTumulte)
            newActive = tmpTumulte.tumulteCards[1].data.activeBiomes[(newValue + 1) % 2]
        } else if (newValue == 5 || newValue == 6) {
            tmpTumulte.tumulteCards[2].hasBeenRevealed = true
            setGlobalGameData(tmpTumulte)
            newActive = tmpTumulte.tumulteCards[2].data.activeBiomes[(newValue + 1) % 2]
        } else if (newValue == 0 || newValue == 7) {
            newActive = { water: true, forest: true, mountain: true }
        }
        tmp.powerCountersActive[side] = newActive
        setPlayerGameData(tmp)
    }

    const changePlayerTumultePositions4Players = (side, newValue) => {
        if (newValue < 0 || newValue > 7) { return }
        let tmp = { ...gameDataPlayers[playerId] }
        tmp.tumultePositions[side == "hero" ? 0 : 1] = newValue
        let newActive = {}

        let playerPos = gameDataGlobal.turnOrder.findIndex((id) => playerId == id)
        // TODO: CLEAN CETTE MERDRE !!!!
        let tmpTumulte = { ...gameDataGlobal }
        if (newValue == 3 || newValue == 4) {
            tmpTumulte.tumulteCards[0].hasBeenRevealed = true
            setGlobalGameData(tmpTumulte)
            newActive = tmpTumulte.tumulteCards[0].data.activeBiomes[(newValue + 1) % 2]
        } else if (newValue == 1 || newValue == 2) {
            tmpTumulte.tumulteCards[playerPos == 0 || playerPos == 3 ? 4 : 2].hasBeenRevealed = true
            setGlobalGameData(tmpTumulte)
            newActive = tmpTumulte.tumulteCards[playerPos == 0 || playerPos == 3 ? 4 : 2].data.activeBiomes[(newValue + 1) % 2]
        } else if (newValue == 5 || newValue == 6) {
            tmpTumulte.tumulteCards[playerPos == 0 || playerPos == 1 ? 1 : 3].hasBeenRevealed = true
            setGlobalGameData(tmpTumulte)
            newActive = tmpTumulte.tumulteCards[playerPos == 0 || playerPos == 3 ? 4 : 2].data.activeBiomes[(newValue + 1) % 2]
        } else if (newValue == 0 || newValue == 7) {
            newActive = { water: true, forest: true, mountain: true }
        }
        tmp.powerCountersActive[side] = newActive
        setPlayerGameData(tmp)
    }

    const setPlayerGameData = (data) => {
        let tmp = { ...gameDataPlayers }
        tmp[playerId] = data
        //setGameDataPlayers(tmp)
        props.sendDataToOpponents({ player: { ...data } })
    }

    const setGlobalGameData = (data) => {
        //setGameDataGlobal(data)
        props.sendDataToOpponents({ global: { ...data } })
    }

    const updateBoardData = (data) => {
        let tmp = { ...gameDataPlayers[playerId] }
        tmp.boardData = data

        if (!useManualMode) {
            tmp.powerCounters = newPowerCountersValue(data, gameDataPlayers[playerId].powerCounters)
        }

        if (gameDataGlobal.lastPlayedCard.cardId != data.lastPlayedCard.cardId) {
            let gTmp = { ...gameDataGlobal }
            gTmp.lastPlayedCard = { playerId: playerId, card: data.lastPlayedCard }
            setGlobalGameData(gTmp)
        }

        setPlayerGameData(tmp)
    }

    const newPowerCountersValue = (data, powerCounters, souldReset) => {

        // On soustrait le power virtuel
        let newPower = powerCounters
        let exp = ["hero", "companion"]
        let powr = ["forest", "mountain", "water"]

        if (souldReset) {
            newPower = JSON.parse(JSON.stringify(powerCountersDefault))
        } else {
            exp.forEach((e) => {
                powr.forEach((p) => {
                    newPower[e][p] -= virtualPower[e][p]
                })
            })
        }

        let newVirtualPower = JSON.parse(JSON.stringify(powerCountersDefault))
        data.heroExpedition.forEach((c) => {
            if (c.power) {
                let boost = c.tokens && c.tokens.boost ? c.tokens.boost : 0
                let asleep = c.tokens && c.tokens.asleep
                newVirtualPower.hero.forest += asleep ? 0 : Number(c.power[0]) + boost
                newVirtualPower.hero.mountain += asleep ? 0 : Number(c.power[1]) + boost
                newVirtualPower.hero.water += asleep ? 0 : Number(c.power[2]) + boost
            }
        })
        data.companionExpedition.forEach((c) => {
            if (c.power) {
                let boost = c.tokens && c.tokens.boost ? c.tokens.boost : 0
                let asleep = c.tokens && c.tokens.asleep
                newVirtualPower.companion.forest += asleep ? 0 : Number(c.power[0]) + boost
                newVirtualPower.companion.mountain += asleep ? 0 : Number(c.power[1]) + boost
                newVirtualPower.companion.water += asleep ? 0 : Number(c.power[2]) + boost
            }
        })
        setVirtualPower(newVirtualPower)

        // On rajoute le nouveau power virtuel
        exp.forEach((e) => {
            powr.forEach((p) => {
                newPower[e][p] += newVirtualPower[e][p]
            })
        })

        exp.forEach((e) => {
            powr.forEach((p) => {
                if (newPower[e][p] < 0) {
                    newPower[e][p] = 0
                }
            })
        })
        return newPower
    }

    const triggerVirtualDeckReset = () => {
        setResetVirtualDeck(resetVirtualDeck + 1)
    }

    const triggerVirtualNewTurn = (forceVal) => {
        if (forceVal != undefined) {
            setNewTurnVirtualDeck(forceVal)
        } else {
            setNewTurnVirtualDeck(newTurnVirtualDeck + 1)
        }
    }

    const triggerVirtualChangeBackground = (forceVal) => {
        if (forceVal != undefined) {
            setChangeBackgroundVirtualDeck(forceVal)
            triggerVirtualNewTurn(forceVal)
        } else {
            setChangeBackgroundVirtualDeck(changeBackgroundVirtualDeck + 1)
        }
    }

    const nextActivePlayer = () => {
        if (gameDataGlobal.turnOrder[gameDataGlobal.activePlayerIndex] == playerId) {
            let gameDatalocalTmp = { ...gameDataGlobal }
            gameDatalocalTmp.activePlayerIndex = (gameDataGlobal.activePlayerIndex + 1) % playersCount
            setGlobalGameData(gameDatalocalTmp)
        }
    }
    // garde valeur de quand iniitalisé
    const handleKeyPressed = (e) => {
        if (e.key === " ") {
            nextActivePlayer()
        } /*else if (e.key == "Enter") {
            triggerVirtualNewTurn()
        }*/
    }

    // For expedition counters
    function add(expedition, counter) {
        let tmp = { ...gameDataPlayers[playerId] }
        let powerCounters = tmp.powerCounters
        if (counter == "all") {
            powerCounters[expedition].forest += 1
            powerCounters[expedition].mountain += 1
            powerCounters[expedition].water += 1
        } else {
            powerCounters[expedition][counter] += 1
        }
        setPlayerGameData(tmp)
    }

    function remove(expedition, counter) {
        let tmp = { ...gameDataPlayers[playerId] }
        let powerCounters = tmp.powerCounters
        if (counter == "all") {
            if (powerCounters[expedition].forest >= 1) {
                powerCounters[expedition].forest -= 1
            }
            if (powerCounters[expedition].mountain >= 1) {
                powerCounters[expedition].mountain -= 1
            }
            if (powerCounters[expedition].water >= 1) {
                powerCounters[expedition].water -= 1
            }
        } else {
            if (powerCounters[expedition][counter] >= 1) {
                powerCounters[expedition][counter] -= 1
            }
        }
        setPlayerGameData(tmp)
    }

    function reset(expedition) {
        let tmp = { ...gameDataPlayers[playerId] }
        let powerCounters = tmp.powerCounters
        powerCounters[expedition].forest = 0
        powerCounters[expedition].mountain = 0
        powerCounters[expedition].water = 0
        setPlayerGameData(tmp)
    }

    const sendMessage = (messageContent) => {
        let newMessage = {
            sender: {
                id: playerId,
                name: gameDataPlayers[playerId].userInfo.name
            },
            message: messageContent
        }
        props.sendDataToOpponents({ newMessage: newMessage })
    }

    const [useDigitalOnly, setUseDigitalOnly] = useState(false)
    const [allowDigitalOnly, setAllowDigitalOnly] = useState(false)
    const [isChangingFocus, setIsChangingFocus] = useState(false)

    useLayoutEffect(() => {
        setIsChangingFocus(true)
        setTimeout(() => {
            setIsChangingFocus(false)
        }, 100)
    }, [focusedPlayerGameSection])

    return (
        <div>
            <div class={(showDebugInfo ? "debugging " : "") + ("game d-flex flex-row") + (hideRightSide ? " hide-right-side" : "") + (hideRightSideVirtual ? " hide-right-side-virtual" : "") + (is2v2 ? " mode2v2" : "") + (isSpectator ? " spectating" : "")} tabIndex={0} onKeyUp={handleKeyPressed}>
                <LeftBar component={
                    <div class="d-flex h-100 flex-column justify-content-between">
                        <div class="d-flex flex-column justify-content-around">
                            <img class="app-icon" src={require('./Icons/app-icon.png')} />
                            <LeftSideButton unselectable={gameDataGlobal.turnOrder[gameDataGlobal.activePlayerIndex] != playerId} icon="right" clicked={() => {
                                nextActivePlayer()
                            }} description={t("game.menu.button_pass.description")} />
                            <TunrCounterTimer turnCounter={gameDataGlobal.turnCounter} />
                        </div>
                        <div class="d-flex flex-column justify-content-around player-only">
                            <div class="position-relative">
                                {!useDigitalOnly && (<LeftSideButton icon="focus" selected={followActivePlayer} clicked={() => {
                                    setFollowActivePlayer(!followActivePlayer)
                                }} description={t("game.menu.button_focus.description")} />)}
                                <LeftSideButton icon="dice" selected={showDiceThrowPanel} clicked={() => {
                                    //setShowDiceThrowPanel(!showDiceThrowPanel)
                                    throwDice(6)
                                }} description={t("game.menu.button_dice_roll.description")}
                                    className="spectator-hidden" />
                                {showDiceThrowPanel && (<PopUpPanel component={
                                    <div class="d-flex flex-column">
                                        <p>{t("game.menu.button_dice_roll.pannel_content")} </p>
                                        <div class="d-flex flex-column justify-content-around">
                                            <Button onClick={() => throwDice(4)} class="wide m-1">4</Button>
                                            <Button onClick={() => throwDice(6)} class="wide m-1">6</Button>
                                            <Button onClick={() => throwDice(8)} class="wide m-1">8</Button>
                                            <Button onClick={() => throwDice(10)} class="wide m-1">10</Button>
                                            <Button onClick={() => throwDice(12)} class="wide m-1">12</Button>
                                            <Button onClick={() => throwDice(20)} class="wide m-1">20</Button>
                                        </div>
                                    </div>
                                } hidePanel={() => setShowDiceThrowPanel(false)} />)}
                            </div>
                            {is2v2 && (<LeftSideButton icon="map" clicked={() => {
                                setIsTumulteMinimized(!isTumulteMinimized)
                            }} description={t("game.menu.button_tumulte.description")} />
                            )}
                            <LeftSideButton icon="refresh" clicked={() => {
                                setShowRestart(true)
                            }} description={t("game.menu.button_start_new_game.description")} />
                        </div><div>
                            <LeftSideButton icon="options" clicked={() => {
                                setShowOptions(true)
                            }} description={t("game.menu.button_options.description")} />
                            <LeftSideButton icon="question-mark" clicked={() => {
                                setShowHelp(true)
                            }} description={t("game.menu.button_help.description")} />
                            <DarkMode />
                        </div>
                        <div class="d-flex flex-column justify-content-around">
                            <LeftSideButton icon="exit" clicked={() => setShowEndSession(true)} description={t("game.menu.button_leave.description")} />
                        </div>
                    </div>
                } />
                {!useDigitalOnly && (<div class="central-section d-flex flex-column">
                    {/*gameDataPlayers[playerId].gameMode == "virtual" && (<div class="quick-tokens-info d-flex flex-column">
                        <p class="mb-2">Hold a key and click a card to quickly add tokens</p>
                        <div class="d-flex flex-row justify-content-between"><p>B/Maj+B</p><p>Boost +/-</p></div>
                        <div class="d-flex flex-row justify-content-between"><p>D/Maj+D</p><p>Divers +/-</p></div>
                        <div class="d-flex flex-row justify-content-between"><p>A</p><p>Anchored</p></div>
                        <div class="d-flex flex-row justify-content-between"><p>E</p><p>Asleep</p></div>
                        <div class="d-flex flex-row justify-content-between"><p>F</p><p>Fleeting</p></div>
            </div>)*/}
                    {allowDigitalOnly && (<PopUpMessage><div class="switch-to-digital-only d-flex flex-column"><p>{t("game.menu.digital_only_allowed")}</p><Button onClick={() => { setUseDigitalOnly(true); setChangeBackgroundVirtualDeck(0); setNewTurnVirtualDeck(0) }}>{t("game.menu.digital_only_allowed_button")}</Button></div></PopUpMessage>)}
                    {!is2v2 && (
                        <div class="top-section d-flex flex-column position-relative">
                            <PowerCounterMemo playersGameData={gameDataPlayers} playersRef={props.playersRef} setPlayersGameData={setPlayerGameData} isSpectator={isSpectator} playerRefIndexes={playerRefIndexes} />
                            <TumulteMemo globalData={gameDataGlobal} playersRef={props.playersRef} playersData={gameDataPlayers} setPlayerPosition={changePlayerTumultePositions} isSpectator={isSpectator} playerRefIndexes={playerRefIndexes} />
                        </div>
                    )}
                    {is2v2 && (
                        <div class={"top-section d-flex flex-column position-relative" + (isTumulteMinimized ? " minimized" : "")}>
                            <div class="position-absolute top-0 start-0 opacity-0 w-100 h-100 top-section-background" onClick={() => setIsTumulteMinimized(true)}></div>
                            <PowerCounterMemo2v2 playersGameData={gameDataPlayers} globalData={gameDataGlobal} playersRef={props.playersRef} setPlayersGameData={setPlayerGameData} isSpectator={isSpectator} playerRefIndexes={playerRefIndexes} />
                            <TumulteSection2v2 globalData={gameDataGlobal} playersRef={props.playersRef} playersData={gameDataPlayers} setPlayerPosition={changePlayerTumultePositions4Players} isSpectator={isSpectator} playerRefIndexes={playerRefIndexes} />
                        </div>
                    )}
                    <div class={"webcam-virtual-section d-flex flex-row" + (QRCodeFound ? " QRCodeFound" : "") + (isChangingFocus ? " changing-focus" : "")}>
                        <div class={"player-game-sections-mini d-flex flex-column justify-content-center" + (focusedPlayerGameSection !== false ? " focused" : " not-focused")} >
                            {gameDataGlobal.turnOrder.map((id, i) => {
                                return (<PlayerGameSectionMemo
                                    key={id}
                                    playerIndex={i}
                                    playerData={gameDataPlayers[id]}
                                    globalData={gameDataGlobal}
                                    setPlayerGameData={setPlayerGameData}
                                    focusedPlayerGameSection={focusedPlayerGameSection}
                                    setFocusedPlayerGameSection={setFocusedPlayerGameSection}
                                    changeFirstPlayer={changeFirstPlayer}
                                    firstPlayerIndex={gameDataGlobal.firstPlayerIndex}
                                    isYourSection={id == playerId}
                                    playerRef={props.playersRef[playerRefIndexes[id]]}
                                    isMini={true}
                                />)
                            })}
                        </div>
                        <div class={"player-game-sections" + (focusedPlayerGameSection !== false ? " focused" : "")}>
                            {gameDataGlobal.turnOrder.map((id, i) => {
                                if (id == playerId) {
                                    return (<PlayerGameSectionMemo
                                        key={id}
                                        playerIndex={i}
                                        isYourSection={true}
                                        playerData={gameDataPlayers[id]}
                                        globalData={gameDataGlobal}
                                        setPlayerGameData={setPlayerGameData}
                                        focusedPlayerGameSection={focusedPlayerGameSection}
                                        setFocusedPlayerGameSection={setFocusedPlayerGameSection}
                                        changeFirstPlayer={changeFirstPlayer}
                                        firstPlayerIndex={gameDataGlobal.firstPlayerIndex}
                                        updateBoardData={(data) => updateBoardData(data)}
                                        triggerVirtualDeckReset={triggerVirtualDeckReset}
                                        resetVirtualDeck={resetVirtualDeck}
                                        newTurnVirtualDeck={newTurnVirtualDeck}
                                        triggerVirtualChangeBackground={triggerVirtualChangeBackground}
                                        changeBackgroundVirtualDeck={changeBackgroundVirtualDeck}
                                        playerRef={props.playersRef[0]}
                                        zoomVirtualCard={zoomVirtualCard}
                                        useManualMode={useManualMode}
                                        powerCounters={<SectionCounter is2v2={is2v2} gameDataPlayers={gameDataPlayers} playerId={props.playerId} id={id} add={add} remove={remove} reset={reset} />} />)
                                } else {
                                    return (
                                        <PlayerGameSectionMemo
                                            key={id}
                                            playerIndex={i}
                                            playerData={gameDataPlayers[id]}
                                            globalData={gameDataGlobal}
                                            setPlayerGameData={setPlayerGameData}
                                            focusedPlayerGameSection={focusedPlayerGameSection}
                                            setFocusedPlayerGameSection={setFocusedPlayerGameSection}
                                            changeFirstPlayer={changeFirstPlayer}
                                            firstPlayerIndex={gameDataGlobal.firstPlayerIndex}
                                            zoomVirtualCard={zoomVirtualCard}
                                            playerRef={props.playersRef[playerRefIndexes[id]]}
                                            isSpectator={isSpectator}
                                            powerCounters={<SectionCounter is2v2={is2v2} gameDataPlayers={gameDataPlayers} playerId={props.playerId} id={id} add={add} remove={remove} reset={reset} />} />)
                                }
                            })}
                        </div>
                    </div>
                    <ZoomedCard zoomedVirtualCard={zoomedVirtualCard} widthVh={25} />
                </div>)}
                {useDigitalOnly && (<div class="central-section digital-only d-flex flex-column">
                    {isSpectator ? (
                        <div>
                            <VirtualBoardGameViewMemo zoomVirtualCard={zoomVirtualCard} isYourSection={false} isOpponent={true} isSpectator={true} boardData={gameDataPlayers[props.playersRef[2].id].boardData} isDigitalOnly={true} useMiniCards={true} />
                            <VirtualBoardGameViewMemo zoomVirtualCard={zoomVirtualCard} isYourSection={true} isOpponent={false} isSpectator={true} boardData={gameDataPlayers[props.playersRef[1].id].boardData} isDigitalOnly={true} useMiniCards={true} />
                            <div class="digital-dice-rolls">
                                <div class="player">
                                    <DiceRolls playerData={gameDataPlayers[props.playersRef[2].id]} />
                                </div>
                                <div class="opponent">
                                    <DiceRolls playerData={gameDataPlayers[props.playersRef[1].id]} />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {props.gameDataGlobal.gameModeInfo.modeId == "_1v1_standard_observer" ? (
                                <VirtualBoardGameViewMemo zoomVirtualCard={zoomVirtualCard} isYourSection={false} isOpponent={true} isSpectator={false} boardData={gameDataPlayers[props.playersRef[2].id].boardData} isDigitalOnly={true} useMiniCards={true} />
                            ) : (
                                <VirtualBoardGameViewMemo zoomVirtualCard={zoomVirtualCard} isYourSection={false} isOpponent={true} isSpectator={false} boardData={gameDataPlayers[props.playersRef[1].id].boardData} isDigitalOnly={true} useMiniCards={true} />
                            )}
                            <VirtualBoardGameViewMemo zoomVirtualCard={zoomVirtualCard} isYourSection={true} isOpponent={false} isSpectator={false} useManualMode={props.useManualMode} updateBoardData={updateBoardData} resetVirtualDeck={resetVirtualDeck} newTurnVirtualDeck={newTurnVirtualDeck} changeBackgroundVirtualDeck={changeBackgroundVirtualDeck} isDigitalOnly={true} useMiniCards={true} />
                            <div class="digital-dice-rolls">
                                <div class="player">
                                    <DiceRolls playerData={gameDataPlayers[playerId]} />
                                </div>
                                <div class="opponent">
                                    {props.gameDataGlobal.gameModeInfo.modeId == "_1v1_standard_observer" ? (
                                        <DiceRolls playerData={gameDataPlayers[props.playersRef[2].id]} />
                                    ) : (
                                        <DiceRolls playerData={gameDataPlayers[props.playersRef[1].id]} />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    {!is2v2 && (
                        <div class="top-section d-flex flex-column position-relative">
                            <PowerCounterMemo playersGameData={gameDataPlayers} playersRef={props.playersRef} setPlayersGameData={setPlayerGameData} isSpectator={isSpectator} playerRefIndexes={playerRefIndexes} />
                            <TumulteMemo globalData={gameDataGlobal} playersRef={props.playersRef} playersData={gameDataPlayers} setPlayerPosition={changePlayerTumultePositions} isSpectator={isSpectator} playerRefIndexes={playerRefIndexes} />
                        </div>
                    )}
                    <div class="opponent digital-user-name">
                        {props.gameDataGlobal.gameModeInfo.modeId == "_1v1_standard_observer" ? (
                            <UserName name={gameDataPlayers[props.playersRef[2].id].userInfo ? gameDataPlayers[props.playersRef[2].id].userInfo.name : ("Player 2")} />
                        ) : (
                            <UserName name={gameDataPlayers[props.playersRef[1].id].userInfo ? gameDataPlayers[props.playersRef[1].id].userInfo.name : ("Player 1")} />
                        )}
                    </div>
                    <div class="digital-user-name">
                        {props.gameDataGlobal.gameModeInfo.modeId == "_1v1_standard_observer" ? (
                            <UserName name={gameDataPlayers[props.playersRef[isSpectator ? 1 : 0].id].userInfo ? gameDataPlayers[props.playersRef[isSpectator ? 1 : 0].id].userInfo.name : ("Player 1")} />
                        ) : (
                            <UserName name={gameDataPlayers[playerId].userInfo ? gameDataPlayers[playerId].userInfo.name : ("Player 2")} />
                        )}
                    </div>
                    {!isSpectator && (
                        <div class="d-flex flex-row top-bar align-items-center">
                            <div class="d-flex flex-row">
                                <ButtonWithConfirmation class="secondary" onClick={() => {
                                    triggerVirtualDeckReset()
                                }}>{t("game.game_section.menu.reset_deck")}</ButtonWithConfirmation>
                                <Button class="secondary" onClick={() => {
                                    triggerVirtualChangeBackground()
                                }}><img src={require('./Icons/image.png')} /></Button>
                            </div>
                        </div>
                    )}
                    {isSpectator ? (
                        <div class={"first-player-image shadowed " + (gameDataGlobal.turnOrder[0] == gameDataGlobal.turnOrder[gameDataGlobal.firstPlayerIndex] ? "you" : "opponent")}>
                            <img src={require('./Icons/first-player.png')} />
                        </div>
                    ) : (
                        <div class={"first-player-image shadowed " + (playerId == gameDataGlobal.turnOrder[gameDataGlobal.firstPlayerIndex] ? "you" : "opponent")}>
                            <img src={require('./Icons/first-player.png')} />
                            <Button onClick={() => changeFirstPlayer()}>{t("game.game_section.next")}</Button>
                        </div>
                    )}
                    <ZoomedCard zoomedVirtualCard={zoomedVirtualCard} widthVh={25} />
                    <LastPlayedCard globalData={gameDataGlobal} playerId={playerId} />
                </div>)}
                <div>
                    <div class="right-side-placeholder"></div>
                    <div class="right-side d-flex flex-column">
                        <div class="show-hide-toggle" onClick={() => { setHideRightSide(!hideRightSide) }} ><img src={require('./Icons/' + (hideRightSide ? "back.png" : "next.png"))} /></div>
                        <div class="menu-selection d-flex flex-row">
                            <Button class="tertiary" selected={rightSideSelection == 0} onClick={() => setRightSideSelection(0)}>Search</Button>
                            <Button class="tertiary" selected={rightSideSelection == 1} onClick={() => setRightSideSelection(1)}>Chat</Button>
                        </div>
                        <div class={rightSideSelection == 0 ? "h-100" : " hide"}>
                            <CardRecognitionColumn latestScannedQRCode={latestScannedQRCode} />
                        </div>
                        <div class={rightSideSelection == 1 ? "h-100" : " hide"}>
                            <Chat chat={props.chat} sendMessage={sendMessage} playerId={playerId} isVisible={rightSideSelection == 1 && !hideRightSide} />
                        </div>
                    </div>
                </div>
            </div>
            {showOptions && (<FullScreenPanel title={t("game.menu.button_options.pannel_title")} component={
                <div class={"d-flex flex-column" + (isSpectator ? " spectating" : "")}>
                    <div class="d-flex flex-row justify-content-between">
                        <p>Change lang:</p>
                        <LanguageSelect />
                    </div>
                    <h3 className="spectator-hidden">{t("game.menu.button_options.pannel_content_faction_title")}</h3>
                    <div class="token-selection d-flex flex-row justify-content-center spectator-hidden">
                        <TokenSelectionButton value={1} playerData={gameDataPlayers[playerId]} setPlayerData={setPlayerGameData} />
                        <TokenSelectionButton value={2} playerData={gameDataPlayers[playerId]} setPlayerData={setPlayerGameData} />
                        <TokenSelectionButton value={3} playerData={gameDataPlayers[playerId]} setPlayerData={setPlayerGameData} />
                        <TokenSelectionButton value={4} playerData={gameDataPlayers[playerId]} setPlayerData={setPlayerGameData} />
                        <TokenSelectionButton value={5} playerData={gameDataPlayers[playerId]} setPlayerData={setPlayerGameData} />
                        <TokenSelectionButton value={6} playerData={gameDataPlayers[playerId]} setPlayerData={setPlayerGameData} />
                    </div>
                    {allowDigitalOnly && (
                        <div class="d-flex flex-column">
                            <h3>Switch to virtual only</h3>
                            <div class="d-flex flex-row justify-content-between">
                                <p>Switch to a face to face layout between the 2 players optimized for playing without webcam.</p>
                                <Button onClick={() => { setUseDigitalOnly(!useDigitalOnly); setChangeBackgroundVirtualDeck(0); setNewTurnVirtualDeck(0) }}>{useDigitalOnly ? "Disable" : "Enable"}</Button>
                            </div>
                        </div>
                    )}

                    {/* 
                    <h3>QR Code dectection</h3>
                    <div class="d-flex flex-column">
                        <p>If your are filming your play area, the app will scan your stream for QR codes and when find one, will send a clear version of the cards to your opponent. In case of performance impact on your computer you can change the scan speed below.</p>
                        <div class="d-flex flex-row justify-content-around">
                            <Button onClick={() => { setQRCodeScanningSpeed(0); QRCodeScanningSpeedRef.current = 0 }} class={"wide" + (QRCodeScanningSpeed == 0 ? "" : " unselected")}>Disabled</Button>
                            <Button onClick={() => { setQRCodeScanningSpeed(1); QRCodeScanningSpeedRef.current = 1 }} class={"wide" + (QRCodeScanningSpeed == 1 ? "" : " unselected")}>Slow</Button>
                            <Button onClick={() => { setQRCodeScanningSpeed(0.5); QRCodeScanningSpeedRef.current = 0.5 }} class={"wide" + (QRCodeScanningSpeed == 0.5 ? "" : " unselected")}>Fast</Button>
                        </div>
                    </div>
                    */}
                    {/*
                    <h3>Manual mode</h3>
                    <div class="d-flex flex-row justify-content-between">
                        <p>Disable automated actions when playing virtual: expedition counters, new turn, deck reset when starting a new game.</p>
                        <Button onClick={() => setUseManualMode(!useManualMode)}>{useManualMode ? "Disable" : "Enable"}</Button>
                    </div>
                    */}
                    <h3 className="spectator-hidden">Unfreeze your stream</h3>
                    <div class="d-flex flex-row justify-content-between spectator-hidden">
                        <p>If your stream is frozen in yours or your opponent screen, use this button to start it again.</p>
                        <Button onClick={() => {
                            if (gameDataPlayers[playerId].gameMode != "webcam") { return }
                            let videoTrack = props.playersRef[0].stream.getTracks()[0]
                            //videoTrack.muted = true;
                            videoTrack.enabled = false;
                            setTimeout(() => {
                                videoTrack.enabled = true;
                            }, 100)

                            //videoTrack.muted = false;
                        }}>Unfreeze</Button>
                    </div>
                    {/* 
                    <h3>Streamer layout</h3>
                    <div class="d-flex flex-row justify-content-between">
                        <p>Change the layout to make it easier for streamers to show a game.</p>
                        <Button onClick={() => setUseStreamerLayout(!useStreamerLayout)}>{useStreamerLayout ? "Disable" : "Enable"}</Button>
                    </div>
                    */}
                </div>
            } hidePanel={() => setShowOptions(false)} />)}
            {showRestart && (<FullScreenPanel title={t("game.menu.button_start_new_game.pannel_title")} component={
                <div class="d-flex flex-column">
                    <p>{t("game.menu.button_start_new_game.pannel_content")}</p>
                    <div class="d-flex flex-row justify-content-around">
                        <Button onClick={() => setShowRestart(false)} class="wide">{t("commons.no")}</Button>
                        <Button onClick={() => {
                            iniGame();
                            setShowRestart(false)
                        }} class="wide">{t("commons.yes")}</Button>
                    </div>
                </div>
            } hidePanel={() => setShowRestart(false)} />)}
            {showEndSession && (<FullScreenPanel title={t("game.menu.button_leave.pannel_title")} component={
                <div class="d-flex flex-column">
                    <p>{t("game.menu.button_leave.pannel_content")}</p>
                    <div class="d-flex flex-row justify-content-around">
                        <button onClick={() => setShowEndSession(false)} class="wide">No</button>
                        <button onClick={() => { props.endCall(); setShowEndSession(false) }} class="wide">Yes</button>
                    </div>
                </div>
            } hidePanel={() => setShowEndSession(false)} />)}
            {showHelp && (<FullScreenPanel title="Help" component={
                <div class="d-flex flex-column">
                    <div className="w-50 mx-auto mb-4">
                    <VirtualShortcutsMemo />
                    </div>
                    <div class="d-flex flex-column">
                        <h3>Camera is setup but no image appears in game</h3>
                        <p>Make sure your browser and this website has authorization to use your camera (on your desktop if using your desktop wecam, or on your phone if using your phone as a webcam).</p>
                    </div>
                    <div class="d-flex flex-column">
                        <h3>If the camera resolution is too low</h3>
                        <p>The maximum resolution is 1080p. If your are below that, make sure your bandwith is high enough, your phone will switch to a lower resolution if not.</p>
                    </div>
                    {/*
                    <div class="d-flex flex-column">
                        <h3>If you are having isue with QR code detection</h3>
                        <p>Make sure the QRCode detection is enabled in the options. Depending on your computer, it might take longer. For a faster recognition, make sure the card is not out of focus and has not too much reflections on it.</p>
                    </div>
                     */}
                    <div class="d-flex flex-column">
                        <h3>Issues with your browser</h3>
                        <p>Chrome and Firefox are recommended. The app has been tested mostly on Chrome and Firefox (with still a few known issues for Firefox). If you have any problem, please send me a screenshot on Discord.</p>
                    </div>
                    <div class="d-flex flex-column">
                        <h3>No audio</h3>
                        <p>There is no audio currently, only video. Use Discord or any other audio app.</p>
                    </div>
                    <div class="d-flex flex-column">
                        <h3>Other issues</h3>
                        <p>Please remember that this is still work in progress. I'm doing my best and things will get better over time.</p>
                    </div>
                </div>
            } hidePanel={() => setShowHelp(false)} />)}
        </div>
    )
}

function TokenSelectionButton(props) {
    const value = props.value
    return (
        <Button class={props.playerData.faction == value ? "selected" : ""} onClick={
            () => {
                let dataTmp = { ...props.playerData }
                dataTmp.faction = value
                props.setPlayerData(dataTmp)
            }}>
            <img src={require('./Tumulte/PlayerIcons/player-' + value + '-1.png')} />
        </Button>
    )
}

function LeftSideButton(props) {
    return (<Button class={"left-side-button" + (props.selected ? " selected" : "") + (props.unselectable ? " unselectable" : "") + (props.className ? " " + props.className : "")} onClick={props.clicked}>
        <img src={require('./Icons/' + props.icon + '.png')} />
        {props.description && (<p class="shadowed">{props.description}</p>)}
    </Button>)
}

function TunrCounterTimer(props) {
    const { t } = useLanguageContext()
    const [turnTimer, setTurnTimer] = useState(0)
    const turnTimerRef = useRef(false)

    useEffect(() => {
        if (turnTimerRef.current == false) {
            turnTimerRef.current = true
            setTimeout(() => {
                if (turnTimerRef.current != "reset") {
                    setTurnTimer(turnTimer + 1)
                    turnTimerRef.current = false
                }
            }, 1000)
        } else if (turnTimerRef.current == "reset") {
            setTimeout(() => {
                setTurnTimer(1)
                turnTimerRef.current = false
            }, 1000)
        }
    }, [turnTimer])

    useEffect(() => {
        setTurnTimer(0)
        turnTimerRef.current = "reset"
    }, [props.turnCounter])

    useEffect(() => {
        setTimeout(() => {
            setTurnTimer(turnTimer + 1)
        }, 1000)
    }, [])

    return (<div class="d-flex flex-column turn-counter">
        <h2>{t("game.menu.turn")} {props.turnCounter}</h2>
        <p>{Math.floor(turnTimer / 60)}:{(turnTimer % 60) < 10 ? "0" : ""}{turnTimer % 60}</p>
    </div>)
}

function LastPlayedCard(props) {
    const { i18n } = useLanguageContext()
    const [displayedCard, setDisplayedCard] = useState(false)
    const [hideDisplayedCard, setHideDisplayedCard] = useState(false)

    useEffect(() => {
        //console.log(props.globalData)
        if (props.globalData.lastPlayedCard) {
            let c = { ...props.globalData.lastPlayedCard }
            if (!c) { setDisplayedCard(false); return }
            if (c.card.isCardHidden) { return }
            if (!displayedCard && props.playerId != c.playerId) {
                setDisplayedCard(c.card)
                setHideDisplayedCard(false)
            } else if (displayedCard.cardId != c.card.cardId && props.playerId != c.playerId) {
                setDisplayedCard(c.card)
                setHideDisplayedCard(false)
            }
        } else {
            setDisplayedCard(false)
        }
    }, [props.globalData])

    useEffect(() => {
        setDisplayedCard(false)
    }, [props.globalData.gameCount])

    if (displayedCard && !hideDisplayedCard) {
        return (<div onMouseEnter={() => setHideDisplayedCard(true)} class="last-played-card shadowed" key={displayedCard.cardId}><ImageMemo src={displayedCard.images ? displayedCard.images[i18n.language] : displayedCard.image} className="shadowed" /></div>)
    }
}

export function ZoomedCard(props) {
    const { i18n } = useLanguageContext()
    const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 })
    const cardSize = useRef({
        width: ((window.innerHeight / 100) * props.widthVh),
        height: ((window.innerHeight / 100) * props.widthVh * 1.4)
    })
    const limits = useRef({
        minT: cardSize.current.height / 2,
        maxT: window.innerHeight - cardSize.current.height / 2,
        minL: cardSize.current.width / 2,
        maxL: window.screen.width - cardSize.current.width / 2,
    })

    useEffect(() => {
        const handleWindowMouseMove = e => {
            let t = e.pageY
            let l = e.pageX
            /*let t = e.pageY
            let l = e.pageX*/
            if (t < limits.current.minT) {
                t = limits.current.minT
            }
            if (t > limits.current.maxT) {
                t = limits.current.maxT
            }
            if (l < limits.current.minL) {
                l = limits.current.minL
            }
            if (l > limits.current.maxL) {
                l = limits.current.maxL
            }
            setCursorPosition({ top: t, left: l });
        };
        window.addEventListener('pointermove', handleWindowMouseMove);

        return () => {
            window.removeEventListener(
                'pointermove',
                handleWindowMouseMove,
            );
        };
    }, []);

    let card = props.zoomedVirtualCard
    if (card && !(cursorPosition.top == 0 && cursorPosition.left == 0)) {
        return (<div style={{ position: 'fixed', ...cursorPosition }} key={card.cardId} class={"zoomed-card zoomed-only" + (cursorPosition.left + cardSize.current.width / 2 < limits.current.maxL ? " other-side" : " ")}>
            <ImageMemo src={card.images ? card.images[i18n.language] : card.image} className="shadowed" />
        </div>)
    }
}

function SectionCounter(props) {
    if (!props.is2v2 || !props.gameDataPlayers[props.id].powerCountersOpponentsId.hero) {
        return undefined
    }
    let opponentHeroId = props.gameDataPlayers[props.id].powerCountersOpponentsId.hero
    let opponentCompanionId = props.gameDataPlayers[props.id].powerCountersOpponentsId.companion
    let opponentCounters = {
        hero: props.gameDataPlayers[opponentHeroId].powerCounters.hero,
        companion: props.gameDataPlayers[opponentCompanionId].powerCounters.companion
    }
    return (<div class="power-counters">
        <Counters className={opponentHeroId == props.playerId ? " player-opponent-counter" : ""} player={props.playerId == props.id ? "player" : "opponent"} expedition="hero" data={props.gameDataPlayers[props.id]} opponentCounters={opponentCounters} add={props.add} reset={props.reset} remove={props.remove} />
        <Counters className={opponentCompanionId == props.playerId ? " player-opponent-counter" : ""} player={props.playerId == props.id ? "player" : "opponent"} expedition="companion" data={props.gameDataPlayers[props.id]} opponentCounters={opponentCounters} add={props.add} reset={props.reset} remove={props.remove} />
        {/*<div class="d-flex flex-column w-100 h-100 position-absolute top-10 start-0">
            <p>{props.id}</p>
            <p>{opponentHeroId}</p>
            <p>{opponentCompanionId}</p>
</div>*/}
    </div>)
}