export const gameModesInfo = {
    _single_player_challenge: {
        modeId: "_single_player_challenge",
        title: "1v1 - Standard",
        description: "Standard is the most popular way to play Altered. Standard asks two players to each bring a deck made up of at least 39 cards and 1 Hero from a single faction.",
        players: 1,
        spectators: 0
    },
    _1v1_standard: {
        modeId: "_1v1_standard",
        title: "1v1 - Standard",
        description: "Standard is the most popular way to play Altered. Standard asks two players to each bring a deck made up of at least 39 cards and 1 Hero from a single faction.",
        players: 2,
        spectators: 0
    },
    _1v1_standard_observer: {
        modeId: "_1v1_standard_observer",
        title: "1v1 - With an observer",
        description: "The other 2 players will be playing a Standard game. The host won't be able to play but will be able to spectate the game. FOR VIRTUAL PLAYERS: BE WARNED THAT THE HOST WILL SEE EVERY PLAYERS HAND.",
        players: 3,
        spectators: 1
    },
    _2v2_standard: {
        modeId: "_2v2_standard",
        title: "2v2 - Standard",
        description: "BETA: for a better experience, wait a bit longer. Constructed 4 players format with two teams facing each others. The first team that manages to have their Hero and Companion meet wins the game.",
        players: 4,
        spectators: 0
    },
    _FFA_4_players: {
        modeId: "_FFA_4_players",
        title: "Free for All - 4 players",
        description: "BETA: for a better experience, wait a bit longer. Constructed 4 players format with two teams facing each others. The first team that manages to have their Hero and Companion meet wins the game.",
        players: 4,
        spectators: 0
    }
    ,
    _draft: {
        modeId: "_draft",
        title: "Draft - Deckbuild",
        description: "Draft mode for 2 to 8 players. Once the draft is complete, the 3 factions you picked the most are automatically added to your decklist. When your deck is built, take your list and find another lobby to play with it in the game mode of your choice.",
        players: "8",
        spectators: 0,
        draftBoostersSets: ["BTG", "BTG", "BTG", "BTG"]
    }
}

export const draftSets = [
    {
        code: "BTG",
        name: "Beyond the Gates"
    }/*,
    {
        code: "TBF",
        name: "Trial by Frost"
    }*/
]