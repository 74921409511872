import '../Commons.css'
import { useLanguageContext } from '../Translations/languageContext'
import GlassBackground from './GlassBackground'

export default function VirtualShortcutsMemo() {
    const { t } = useLanguageContext()

    return (
        <div class="virtual-shortcuts-memo d-flex flex-column position-relative shadowed-light">
            <h2 class="mb-2">{t("virtual_board.board.quick_tokens.content")}</h2>
            <hr></hr>
            <div class="d-flex flex-row justify-content-between"><p>B/Maj+B</p><p class="text-capitalize">{t("virtual_board.board.quick_tokens.boost")} +/-</p></div>
            <hr></hr>
            <div class="d-flex flex-row justify-content-between"><p>D/Maj+D</p><p class="text-capitalize">{t("virtual_board.board.quick_tokens.divers")} +/-</p></div>
            <hr></hr>
            <div class="d-flex flex-row justify-content-between"><p>A</p><p>{t("virtual_board.board.quick_tokens.anchored")}</p></div>
            <hr></hr>
            <div class="d-flex flex-row justify-content-between"><p>E</p><p>{t("virtual_board.board.quick_tokens.asleep")}</p></div>
            <hr></hr>
            <div class="d-flex flex-row justify-content-between"><p>F</p><p>{t("virtual_board.board.quick_tokens.fleeting")}</p></div>
            <hr></hr>
            <div class="d-flex flex-row justify-content-between"><p>X</p><p>{t("virtual_board.board.quick_tokens.clear")}</p></div>
            <hr></hr>
            <div class="d-flex flex-row justify-content-between"><p>T</p><p>{t("virtual_board.board.quick_tokens.exhaust")}</p></div>
        </div>
    )
}